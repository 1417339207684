import React, { useEffect } from "react";
import Layout from "../components/Layout";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Breadcumb from "../components/Breadcumb";
import BrandSlider from "../components/BrandSlider";

export default function WhoWeAre(props) {
  useEffect(() => {
    props.setProgress(100);
  }, []);
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Future Flix | Who We Are </title>
      </Helmet>
      <main className="main retargeting-page">
        <Breadcumb title="Who We Are" desc="We love being pioneers" />

        <section className="section content-image-section sec-space">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 order-lg-1 order-2" data-aos="fade-right" data-aos-offset="10">
                <div className="heading-center">
                  Your Vision, Our Commitment: The{" "}
                  <span className="text-primary">Future Flix</span> Way
                </div>
                <p>
                  We are a team of creative enthusiasts, fueled by AI powered
                  innovation and armed with expertise in digital marketing.
                  We're on a mission to deliver outcomes that truly resonate.
                  Our playground is where imagination meets technology, crafting
                  compelling digital marketing campaigns that empower businesses
                  of every magnitude. Drawing inspiration from our name, Future
                  Flix, we conceptualize futuristic ad streaming with contextual
                  relevance that empower businesses of every magnitude. Embark
                  on a journey of futuristic creativity with us!
                </p>

                <a href="#values" className="btn btn-primary  mt-3 mb-3">
                  Learn More
                </a>
              </div>
              <div className="col-lg-6 text-lg-end mb-lg-0 mb-4 order-lg-2 order-1" data-aos="fade-left">
                <div className="img">
                  <img
                    src="./images/office-vector.png"
                    alt="office-vector"
                    width="609"
                    height="520"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section
          className="section content-image-section sec-space sec-bg"
          id="values"
        >
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6 text-lg-end mb-lg-0 mb-4" data-aos="fade-right">
                <div className="img pe-lg-4">
                  <img
                    src="./images/megnet-vector.png"
                    alt="megnet-vector"
                    width="635"
                    height="398"
                  />
                </div>
              </div>
              <div className="col-lg-6" data-aos="fade-left">
                <div className="heading-center mt-5">
                  Our <span className="text-primary">Values</span>
                </div>
                <p>
                  At Future Flix Media, our work culture revolves around our
                  core values. We cherish collaboration, fuel creativity, and
                  prioritize client contentment. Our pledge is to offer bespoke
                  solutions, finely tuned to meet the distinct needs of every
                  client.
                </p>

                <p>
                  <b> Client Dedication:</b> We hold our clients' trust in high
                  regard, and our relentless determination showcases our
                  unwavering commitment.
                </p>
                <p>
                  <b>Unrivaled Excellence:</b> We serve innovation on a silver
                  platter, leaving outdated solutions in the dust.
                </p>
                <p>
                  <b>Innovation:</b> In a world teeming with challenges, we
                  believe in rewriting the rules. It's not just about thinking
                  outside the box; it's about building a whole new box.
                </p>

                <a href="/contact-us" className="btn btn-primary  mt-5 mb-3">
                  Contact Us Now!
                </a>
              </div>
            </div>
          </div>
        </section>
        <section className="section sec-space team-section">
        <div className="container">
            <div className="text-center">
                <div className="heading-center mb-md-5">Meet the  <span className="text-primary">Team</span></div>
            </div>
            <div className="future-avdt justify-content-between">
                <div className="items border-0 px-md-3 p-2 pb-md-4 pb-1 w-md-auto text-center">
                    <div className="team-img">
                        <img src="./images/payal-jani.png" alt="payal-jani" width="300" height="300" />
                    </div>
                    <div className="name">
                        Payal Jani
                        <div className="desination">
                            Founder 
                        </div>              
                    </div> 
                </div> 
                <div className="items border-0 px-md-3 p-2 pb-md-4 pb-1 w-md-auto text-center">
                    <div className="team-img">
                        <img src="./images/ajay-shahu.png" alt="ajay-shahu" width="300" height="300" />
                    </div>
                    <div className="name">
                        Ajay Sahu
                        <div className="desination">
                            Tech Partner
                        </div>              
                    </div> 
                </div> 
                <div className="items border-0 px-md-3 p-2 pb-md-4 pb-1 w-md-auto text-center">
                    <div className="team-img">
                        <img src="./images/daryl.png" alt="daryl" width="300" height="300" />
                    </div>
                    <div className="name">
                        Daryl
                        <div className="desination">
                            Tech Partner
                        </div>              
                    </div> 
                </div>
                <div className="items border-0 px-md-3 p-2 pb-md-4 pb-1 mt-5 w-md-auto text-center">
                    <div className="team-img">
                        <img src="./images/raheel-merchant.png" alt="raheel-merchant" width="300" height="300" />
                    </div>
                    <div className="name">
                        Raheel Merchant
                        <div className="desination">
                            Digital Marketing Manager
                        </div>              
                    </div> 
                </div>
                <div className="items border-0 px-md-3 p-2 pb-md-4 pb-1 mt-5 w-md-auto text-center">
                    <div className="team-img">
                        <img src="./images/karan-handa.png" alt="karan-handa" width="300" height="300" />
                    </div>
                    <div className="name">
                        Karan Handa
                        <div className="desination">
                            Ad Sales
                        </div>              
                    </div> 
                </div>
                <div className="items border-0 px-md-3 p-2 pb-md-4 pb-1 mt-5 w-md-auto text-center">
                    <div className="team-img">
                        <img src="./images/hena-hallak.png" alt="hena-hallak" width="300" height="300" />
                    </div>
                    <div className="name">
                        Hana Hallak
                        <div className="desination">
                            Executive Assistant
                        </div>              
                    </div> 
                </div>
            </div>
        </div>
      </section>
      </main>
    </Layout>
  );
}
