import React, { useEffect } from "react";
import Layout from "../components/Layout";
import { Helmet } from "react-helmet";
import Breadcumb from "../components/Breadcumb";

export default function HowWeDo(props) {
  useEffect(() => {
    props.setProgress(100);
  }, []);
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Future Flix | HowWeDo </title>
      </Helmet>
      <main className="main retargeting-page">
        <Breadcumb title="How we Do?" desc="Excited to know how we do this?" />

        <section className="section content-section text-center futur-collaborate">
          <div className="container" data-aos="fade-up" data-aos-offset="10">
            <div className="heading-center">
              Cit<span className="text-primary">F</span>lix: Future Flix
              Collaborates with Citrusberry
            </div>
            <div className="inner-content mx-auto">
              <p>
                Citrusberry and Future Flix unite in an exciting collaboration,
                harnessing the potential of their groundbreaking AI technology,
                <span className="text-primary"><b>"X rae,"</b></span> to transform advertising. CitFlix is a centralized
                platform that provides real-time data and analytics allowing
                users to track key performance indicators, monitor campaign
                progress, and make data-driven decisions for optimizing
                marketing strategies.
              </p>
              <p>
                {" "}
                By fusing creativity with cutting-edge analytics, they create
                dynamic campaigns that captivate audiences and drive
                unprecedented conversions. This powerful partnership sets a new
                standard in advertising, offering personalized and engaging
                content that resonates with audiences on a micro-level.
                Together, Citrusberry and Future Flix, now CitFlix are reshaping
                the advertising landscape, delivering impactful campaigns that
                leave a lasting impression.
              </p>
            </div>
          </div>
        </section>

        <section className="section content-image-section sec-space sec-bg">
          <div className="container">
            <div className="row mb-4 align-items-center">
              <div className="col-lg-7" data-aos="fade-right">
                <div className="heading-center">
                  Pioneering the Future of <br />{" "}
                  <span className="text-primary"> Contextual Advertising</span>
                </div>
                <p className="mb-4">
                  Navigating the digital landscape to captivate your ideal
                  audience has never been trickier. But fear not! With Future
                  Flix Media's cutting-edge Contextual Advertising platform,
                  connecting with your audience is a breeze. Imagine presenting
                  the perfect ads, precisely where they matter, exactly when
                  they'll make an impact. All effortlessly managed in a
                  user-friendly hub, complete with crystal-clear insights.
                </p>
                <p className="mb-3">
                  {" "}
                  Our in-content ads capture and retain focus, utilizing eye
                  tracking insights. Unleash effective ad engagement now.
                  Welcome to the future of advertising with Future Flix Media –
                  where content meets context!
                </p>
                <a
                  href="/contact-us"
                  className="btn btn-primary text-uppercase  mt-3 mb-3"
                >
                  Book Demo
                </a>
              </div>
              <div className="col-lg-5 text-lg-end mb-lg-0 mb-4" data-aos="fade-left">
                <div className="img">
                  <img
                    src="./images/mobile-apps.png"
                    alt="mobile-apps"
                    width="565"
                    height="305"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="section content-image-section sec-space">
          <div className="container">
            <div className="row mb-4 align-items-center">
              <div className="col-lg-7" data-aos="fade-right">
                <div className="heading-center"> Our Demand Side Platform</div>
                <p className="mb-4">
                  {" "}
                  Supercharge your brand with CitFlix: Where programmatic
                  advertising meets AI precision! Unleash the true power of
                  programmatic advertising and revolutionize your ad campaigns
                  like never before. Say hello to our state-of-the-art Demand
                  Side Platform (DSP) infused with advanced AI capabilities that
                  make targeting a breeze. Tap into the magic of Artificial
                  Intelligence and Machine Learning (AIML) to optimize your ads,
                  dazzle your audience with personalized experiences, and even
                  predict consumer behavior. Get ready to reach new heights in
                  engagement, maximize your ROI, and become a part of the ad
                  revolution! Don't miss out – join us today!
                </p>

                <a
                  href="/contact-us"
                  className="btn btn-primary text-uppercase  mt-3 mb-3"
                >
                  Get Started
                </a>
              </div>
              <div className="col-lg-5 text-lg-end mb-lg-0 mb-4" data-aos="fade-left">
                <div className="img">
                  <img
                    src="./images/dashboard-img.png"
                    alt="dashboard"
                    width="609"
                    height="348"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="section content-image-section sec-space sec-bg text-center">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="heading-center">Our Supply Side Platform</div>
                <p className="mb-lg-5 mb-4">
                  {" "}
                  Ready to supercharge your digital revenue streams? Look no
                  further! Our cutting-edge Supply Side Platform (SSP) is here
                  to empower publishers like you with unmatched monetization
                  opportunities. Get ready to access millions of premium
                  inventory and take your ad space to the next level! Our
                  advanced computer vision and contextual offering ensure that
                  you deliver precise and relevant ads to your audience, making
                  every impression count. Say hello to maximized revenue,
                  optimized fill rates, and delighted users. Don't miss out on
                  the publishing revolution – join us today and let's skyrocket
                  your success together!
                </p>

                <div className="img-wraps mb-lg-5 mb-3" data-aos="fade-up">
                  <img src="./images/brand-img.png" alt="brand-img" />
                </div>

                <div className="premium-support-section p-0">
                  <a href="/contact-us" className="btn btn-primary mt-0">
                    GET STARTED
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="section adsolution-citflix sec-space text-center">
          <div className="container">
            <h2 className="heading-center mb-lg-5">
              Ad Solutions with<span className="text-primary"> CitFlix</span>{" "}
            </h2>
            <div className="future-avdt text-center">
              <div className="items border-0 p-0" data-aos="fade-up" data-aos-offset="500">
                <div className="img-box mb-3">
                  <img
                    src="./images/in-image.png"
                    alt="in-image"
                    width="263"
                    height="205"
                  />
                </div>
                <div className="title">In-Image</div>
                <p className="pera">
                  Flawlessly integrating images with the content, grabbing
                  users' focus where attention peaks
                </p>
              </div>
              <div className="items border-0 p-0" data-aos="fade-up" data-aos-offset="500">
                <div className="img-box mb-3">
                  <img
                    src="./images/in-screen.png"
                    alt="in-screen"
                    width="263"
                    height="205"
                  />
                </div>
                <div className="title">In-Screen</div>
                <p className="pera">
                  As users scroll through relevant content, our ads stays fixed
                  at the bottom of the page, always in view.
                </p>
              </div>
              <div className="items border-0 p-0" data-aos="fade-up" data-aos-offset="500">
                <div className="img-box mb-3">
                  <img
                    src="./images/in-article.png"
                    alt="in-article"
                    width="263"
                    height="205"
                  />
                </div>
                <div className="title">In-Article</div>
                <p className="pera">
                  Our dynamic multi-frame interactive unit, illuminating ad
                  messaging seamlessly within contextually-relevant content.
                </p>
              </div>
              <div className="items border-0 p-0" data-aos="fade-up" data-aos-offset="500">
                <div className="img-box mb-3">
                  <img
                    src="./images/skin.png"
                    alt="in-skin"
                    width="263"
                    height="205"
                  />
                </div>
                <div className="title">Skins</div>
                <p className="pera">
                  Unveil our screen-expanding contextual ads that seamlessly
                  melds with content, enriched with diverse interactive
                  elements.
                </p>
              </div>
              <div className="items border-0 p-0" data-aos="fade-up" data-aos-offset="500">
                <div className="img-box mb-3">
                  <img
                    src="./images/standerd-display.png"
                    alt="standerd-display"
                    width="263"
                    height="205"
                  />
                </div>
                <div className="title">Standard Display</div>
                <p className="pera">
                  Elevate your strategy with our tailor-made In-Image and
                  In-Screen ads, seamlessly integrating standard banners for
                  effortless, turnkey solutions.
                </p>
              </div>
            </div>
          </div>
        </section>
      </main>
    </Layout>
  );
}
