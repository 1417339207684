import React from "react";

export default function Breadcumb(props) {
    const {title, desc} = props;
  return (
    <div className="ads-section">
      <div className="container">
        <div className="ads">
          <div className="ads-title">{title}</div>
          <p className="ads-des">
            {desc}
          </p>
        </div>
      </div>
    </div>
  );
}
