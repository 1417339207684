import React, { useEffect } from "react";
import Layout from "../components/Layout";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Breadcumb from "../components/Breadcumb";

export default function ProgramAds(props) {
  useEffect(() => {
    props.setProgress(100);
  }, []);

  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Future Flix | Programmatic Ads </title>
      </Helmet>
      <main class="main retargeting-page">
        <Breadcumb
          title="Programmatic  Ads"
          desc="Global reach. Local targeting."
        />

        <section class="section brand section py-3 border-bottom">
          <div class="container">
            <div class="d-flex align-items-center flex-wrap justify-content-between">
              <div class="item m-2 m-lg-3 ">
                <img
                  src="./images/newyorktime.png"
                  alt="newyorktime"
                  width="210"
                  height="29"
                />
              </div>
              <div class="item m-2 m-lg-3 ">
                <img src="./images/bbc.png" alt="bbc" width="113" height="32" />
              </div>
              <div class="item m-2 m-lg-3 ">
                <img
                  src="./images/theweatherchanel.png"
                  alt="theweatherchanel"
                  width="71"
                  height="71"
                />
              </div>
              <div class="item m-2 m-lg-3 ">
                <img src="./images/cnn.png" alt="cnn" width="80" height="38" />
              </div>
              <div class="item m-2 m-lg-3 ">
                <img
                  src="./images/usatoday.png"
                  alt="usatoday"
                  width="148"
                  height="50"
                />
              </div>
              <div class="item m-2 m-lg-3 ">
                <img
                  src="./images/bloomberg.png"
                  alt="bloomberg"
                  width="135"
                  height="25"
                />
              </div>
            </div>
          </div>
        </section>

        <section class="section content-section text-center overflow-hidden">
          <div class="container">
            <div class="heading-center" data-aos="fade-up">
              Reach the right customers where it matters
            </div>
            <p data-aos="fade-up">
              With Future Flix your ads can be published on thousands of popular
              websites, apps and major ad exchanges all over the world. Go wide,
              or choose a local market that suits your needs.
            </p>
            <Link
              to="#"
              class="btn btn-primary text-uppercase mt-lg-5 mt-3"
              data-aos="fade-up"
            >
              Book Demo
            </Link>
          </div>
        </section>
        <section class="section content-image-section sec-space sec-bg overflow-hidden">
          <div class="container">
            <div class="row mb-4">
              <div class="col-lg-6" data-aos="fade-left">
                <div class="heading-center">How does it work?</div>
                <p>
                  Once your campaign is ready to launch, Clara AI will publish
                  the ads for your potential customers to view on their
                  computers, tablets and mobile screens. Advanced automatic
                  targeting selects the right websites and apps based on where
                  your customers are most likely to be.
                </p>
              </div>
              <div
                class="col-lg-6 text-lg-end mb-lg-0 mb-4"
                data-aos="fade-right"
              >
                <div class="img">
                  <img
                    src="./images/how-work.png"
                    alt="how-work"
                    width="565"
                    height="345"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6 order-lg-1 order-2" data-aos="fade-left">
                <div class="img mb-lg-0 mb-4">
                  <img
                    src="./images/major-exchange.png"
                    alt="major-exchange"
                    width="545"
                    height="342"
                  />
                </div>
              </div>
              <div class="col-lg-6 order-lg-2 order-1" data-aos="fade-right">
                <div class="heading-center">
                  Seamlessly connect to all major Ad Exchanges
                </div>
                <p>
                  {" "}
                  Get access to premium inventory on marketplaces like Google
                  DoubleClick, Facebook and AppNexus.
                </p>
                <Link to="#" class="btn btn-primary text-uppercase  mt-3 mb-3">
                  Book Demo
                </Link>
              </div>
            </div>
          </div>
        </section>

        <section class="section text-brand sec-space text-center pb-2">
          <div class="container">
            <div class="heading-center">
              Marketplaces where your ads may appear include:
            </div>
            <div class="d-flex align-items-center flex-wrap justify-content-between">
              <div class="item px-4 m-2  fs-5 lh-1">
                Brand <br />
                LOGO
              </div>
              <div class="item px-4 m-2 fs-5 lh-1">
                Brand <br />
                LOGO
              </div>
              <div class="item px-4 m-2 fs-5 lh-1">
                Brand <br />
                LOGO
              </div>
              <div class="item px-4 m-2 fs-5 lh-1">
                Brand <br />
                LOGO
              </div>
              <div class="item px-4 m-2 fs-5 lh-1">
                Brand
                <br />
                LOGO
              </div>
              <div class="item px-4 m-2 fs-5 lh-1">
                Brand
                <br />
                LOGO
              </div>
            </div>
          </div>
        </section>
        <section class="section content-image-section sec-space overflow-hidden">
          <div class="container">
            <div class="row mb-4">
              <div class="col-lg-8" data-aos="fade-left">
                <div class="heading-center">
                  How do I know my ads are displayed where they need to be?
                </div>
                <p>
                  {" "}
                  When you publish your ads through Future Flix, you can be sure
                  they are seen by the right people, in the right context. You
                  can whitelist or blacklist specific websites right in the
                  platform. This gives you the power to decide exactly where
                  your ads appear. Sophisticated fraud detection technology
                  ensures your ads are seen by real human beings and not bots.
                </p>
                <Link to="#" class="btn btn-primary text-uppercase  mt-3 mb-3">
                  Book Demo
                </Link>
              </div>
              <div
                class="col-lg-4 text-lg-center mb-lg-0 mb-4"
                data-aos="fade-right"
              >
                <div class="img">
                  <img
                    src="./images/desktop.png"
                    alt="desktop"
                    width="315"
                    height="223"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6 order-lg-1 order-2" data-aos="fade-left">
                <div class="img mb-lg-0 mb-4 mt-lg-4">
                  <img
                    src="./images/add-exchange.png"
                    alt="add-exchange"
                    width="565"
                    height="362"
                  />
                </div>
              </div>
              <div class="col-lg-6 order-lg-2 order-1" data-aos="fade-right">
                <div class="heading-center">
                  How can I keep track of my ads across the web?
                </div>
                <p>
                  We believe in 100% transparency and give you access to the
                  exact report provided by our inventory partners. The report
                  shows the websites and apps where your ads have been
                  displayed, together with the cost to place them there.
                </p>
                <p>
                  This means you’re always in full control of your spending.
                  Doesn’t that sound great?
                </p>
                <p>
                  The best thing is that you don’t have to hire campaign
                  specialists to manually optimise your campaign based on which
                  websites perform better or worse – Clara AI will optimise it
                  for you automatically!
                </p>
                <Link to="#" class="btn btn-primary text-uppercase  mt-3 mb-3">
                  Book Demo
                </Link>
              </div>
            </div>
          </div>
        </section>
        <div class="premium-support-section border-top pt-3 pt-lg-5">
          <div class="container" data-aos="fade-up">
            <div class="premium-support-title">
              High Performance - <span>Easy Setup</span> - Premium Support
            </div>
            <Link to="#" class="btn btn-primary">
              GET STARTED
            </Link>
          </div>
        </div>
      </main>
    </Layout>
  );
}
