import React, { useState } from "react";
import { Link } from "react-router-dom";
import { BsInstagram, BsFacebook, BsLinkedin } from "react-icons/bs";

export default function Header() {
  const [isNavPressed, setNavPressed] = useState(false);
  const [isNavOpen, setNavOpen] = useState(false);
  const [subMenu, setSubMenu] = useState(false);

  const handleToggler = () => {
    setNavPressed((prevState) => !prevState);
    setNavOpen((prevState) => !prevState);
  };
  return (
    <header className="main_header">
      <nav className="navbar navbar-expand-lg">
        <div className="container">
          <div className="header-inner">
            <div className="header-start">
              <Link className="" to="/">
                <img
                  src="/images/logo.png"
                  alt="logo"
                  width={263}
                  height={73}
                  className="d-lg-block d-none"
                />
                <img
                  src="/images/logo.png"
                  alt="logo"
                  width={200}
                  height={73}
                  className="d-block d-lg-none"
                />
              </Link>
            </div>
            <div className="header-middle">
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
                onClick={handleToggler}
              >
                <div className="menu_icon">
                  <span className="one"></span>
                  <span className="two"></span>
                  <span className="three"></span>
                </div>
              </button>
              <div
                className={`navbar-collapse ${isNavPressed ? "pressed" : ""}`}
                id="navbarSupportedContent"
              >
                <div
                  className="close-icon navbar-toggler"
                  onClick={handleToggler}
                >
                  <svg
                    width="15"
                    height="15"
                    viewBox="0 0 15 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="close"
                  >
                    <line
                      x1="0.707107"
                      y1="0.747971"
                      x2="13.6791"
                      y2="13.72"
                      stroke="black"
                      strokeWidth="2"
                    ></line>
                    <line
                      x1="13.9486"
                      y1="0.715526"
                      x2="0.822112"
                      y2="13.5312"
                      stroke="black"
                      strokeWidth="2"
                    ></line>
                  </svg>
                </div>
                <ul className="navbar-nav mb-2 mb-lg-0  ms-auto ">
                  <li className="nav-item">
                    <a href="/" className="nav-link" title="Home">
                      Home
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      href="/who-we-are"
                      className="nav-link"
                      title="Who We Are?"
                    >
                      Who We Are?
                    </a>
                  </li>
                  <li className="nav-item">
                    <div
                      className="nav-link chevron"
                      title="What We Do ?"
                      onClick={() => isNavPressed && setSubMenu(!subMenu)}
                    >
                      What We Do ?
                    </div>
                    <div
                      class={`header-dropdown-menu ${
                        isNavPressed ? (subMenu ? "d-block" : "d-none") : ""
                      }`}
                    >
                      <ul class="drop-list-item list-unstyled ps-0">
                        <li class="item">
                          <a href="/contextual" class="nav-link">
                            Contextual Ads
                          </a>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li className="nav-item">
                    <a
                      href="/how-we-do"
                      className="nav-link"
                      title="How We Do?"
                    >
                      How We Do?
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      href="/contact-us"
                      className="nav-link"
                      title="Contact Us"
                    >
                      Contact Us
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="header-end">
              <div className="d-flex flex-column">
                <ul className="list-unstyled p-0 m-0 d-flex align-items-center justify-content-center mb-2">
                  <li className="item me-2">
                    <a
                      href="https://www.linkedin.com/company/future-flix-media/"
                      target="_blank"
                      title="Linkdin"
                    >
                      <span className="icon socialicon text-white">
                        <BsLinkedin></BsLinkedin>
                      </span>
                    </a>
                  </li>
                  <li className="item me-2">
                    <a
                      href="https://www.instagram.com/futureflixmedia/"
                      target="_blank"
                      title="Instagram"
                    >
                      <span className="icon socialicon text-white">
                        <BsInstagram></BsInstagram>
                      </span>
                    </a>
                  </li>
                  <li className="item">
                    <a
                      href="https://www.facebook.com/futureflixmedia"
                      target="_blank"
                      title="Facebook"
                    >
                      <span className="icon socialicon text-white">
                        <BsFacebook></BsFacebook>
                      </span>
                    </a>
                  </li>
                </ul>
                <a href="/contact-us" className="btn btn-primary join-us-btn">
                  Join Us
                </a>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
}
