import React, { useEffect } from "react";
import Layout from "../components/Layout";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Breadcumb from "../components/Breadcumb";

export default function Nativeadvr(props) {
  useEffect(() => {
    props.setProgress(100);
  }, []);
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Future Flix | Native Advertising </title>
      </Helmet>
      <main className="main retargeting-page">
        <Breadcumb
          title="Native Advertising"
          desc="Give your content an audience"
        />

        <section className="section content-image-section sec-space">
          <div className="container">
            <div className="row mb-4">
              <div className="col-lg-6">
                <div className="heading-center">
                  Engage your audience with native
                </div>
                <p>
                  Native advertising enables you to reach your target audience
                  in a way that engages them by naturally blending into the
                  website.
                </p>
                <p>
                  Future Flix AI collects user data to show the right native
                  ads, at the right time, to people who are most likely to be
                  interested in what you’re offering. With Match2One Native
                  you’ll get increased engagement and high-quality traffic to
                  your website.
                </p>

                <Link
                  href="#"
                  className="btn btn-primary text-uppercase  mt-3 mb-3"
                >
                  Book Demo
                </Link>
              </div>
              <div className="col-lg-6 text-lg-end mb-lg-0 mb-4">
                <div className="img">
                  <img
                    src="./images/app-mockup.png"
                    alt="how-work"
                    width="602"
                    height="254"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="section text-brand sec-space sec-bg">
          <div className="container">
            <div className="brand-title text-center mb-4">
              Brands growing with Match2Onee
            </div>
            <div className="d-flex align-items-center flex-wrap justify-content-between mb-4 mb-lg-5">
              <div className="item px-4 m-2  fs-5 lh-1">
                Brand <br />
                LOGO
              </div>
              <div className="item px-4 m-2 fs-5 lh-1">
                Brand <br />
                LOGO
              </div>
              <div className="item px-4 m-2 fs-5 lh-1">
                Brand <br />
                LOGO
              </div>
              <div className="item px-4 m-2 fs-5 lh-1">
                Brand <br />
                LOGO
              </div>
              <div className="item px-4 m-2 fs-5 lh-1">
                Brand
                <br />
                LOGO
              </div>
              <div className="item px-4 m-2 fs-5 lh-1">
                Brand
                <br />
                LOGO
              </div>
            </div>

            <div className="row">
              <div className="col-lg-6 order-lg-1 order-2">
                <div className="img mb-lg-0 mb-4 mt-lg-4">
                  <img
                    src="./images/desktop-screen.png"
                    alt="desktop-screen"
                    width="543"
                    height="326"
                  />
                </div>
              </div>
              <div className="col-lg-6 order-lg-2 order-1">
                <div className="heading-center">
                  Bring your content to the right person in the right context
                </div>
                <p>
                  {" "}
                  Bring your content to the right person in the right context
                  Make sure nobody misses your message – reach your audience
                  where your ads naturally blend into a website. This increases
                  the number of readers that look at your ads and encourages
                  them to click through to your content.
                </p>
                <p>
                  Scale your content with programmatic native campaigns. Future
                  Flix Native enables you to create and show native ads to the
                  right people to achieve high ROAS.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="section text-brand sec-space">
          <div className="container-sm">
            <div className="heading-center text-center">
              Engage your audience with native
            </div>

            <div className="three-col-view futurefix-native mt-lg-5 gap-lg-5 align-items-start">
              <div className="items text-center">
                <div className="img-wrap mb-4">
                  <img
                    src="./images/Capacity-min.png"
                    alt="Capacity"
                    width="198"
                    height="155"
                  />
                </div>
                <div className="item-text">Capacity</div>
                <p className="desc">
                  Get access to all premium publishers for your native ads in a
                  platform that shares all the valuable insights. With our
                  subscription model pricing, you’ll never have to worry about
                  any unexpected costs.
                </p>
              </div>
              <div className="items text-center">
                <div className="img-wrap mb-4">
                  <img
                    src="./images/Synergy-min.png"
                    alt="Synergy"
                    width="210"
                    height="202"
                  />
                </div>
                <div className="item-text">Synergy</div>
                <p className="desc">
                  Our AI-powered platform will automatically cross-pollinate
                  learnings between display and native campaigns to maximize
                  results. The platform can be integrated with Google Analytics
                  to optimize campaigns in finding the right traffic.
                </p>
              </div>
              <div className="items text-center">
                <div className="img-wrap mb-4">
                  <img
                    src="./images/Quality-min.png"
                    alt="Quality"
                    width="198"
                    height="155"
                  />
                </div>
                <div className="item-text">Quality</div>
                <p className="desc">
                  We combine smart targeting with the right native ad
                  placements. By focusing on advertising in brand-safe
                  environments and premium websites, we ensure that your
                  campaign delivery is worthy of your brand.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="section brand sec-space border-top">
          <div className="container">
            <div className="brand-title text-center mb-4">Brand</div>
            <div className="d-flex align-items-center flex-wrap justify-content-between">
              <div className="item m-2 m-lg-3 ">
                <img
                  src="./images/newyorktime.png"
                  alt="newyorktime"
                  width="210"
                  height="29"
                />
              </div>
              <div className="item m-2 m-lg-3 ">
                <img src="./images/bbc.png" alt="bbc" width="113" height="32" />
              </div>
              <div className="item m-2 m-lg-3 ">
                <img
                  src="./images/theweatherchanel.png"
                  alt="theweatherchanel"
                  width="71"
                  height="71"
                />
              </div>
              <div className="item m-2 m-lg-3 ">
                <img src="./images/cnn.png" alt="cnn" width="80" height="38" />
              </div>
              <div className="item m-2 m-lg-3 ">
                <img
                  src="./images/usatoday.png"
                  alt="usatoday"
                  width="148"
                  height="50"
                />
              </div>
              <div className="item m-2 m-lg-3 ">
                <img
                  src="./images/bloomberg.png"
                  alt="bloomberg"
                  width="135"
                  height="25"
                />
              </div>
            </div>
          </div>
        </section>

        <section className="section gettng-started sec-space sec-bg">
          <div className="container-sm">
            <div className="heading-center text-center">Getting Started</div>

            <div className="three-col-view futurefix-native mt-lg-5 gap-lg-5 align-items-start">
              <div className="items text-center">
                <div className="img-wrap mb-4">
                  <img src="./images/one.svg" alt="one" />
                </div>
                <p className="desc">
                  Submit the “Get Started” form and we’ll be in touch to discuss
                  your goals and show you the possibilities of our platform.
                </p>
              </div>

              <div className="items text-center">
                <div className="img-wrap mb-4">
                  <img src="./images/two.svg" alt="two" />
                </div>
                <p className="desc">
                  Our specialists will present you the right strategy to get the
                  most out of your programmatic native budget. You’ll get advice
                  on where to show your ads and which audience to target.
                </p>
              </div>
              <div className="items text-center">
                <div className="img-wrap mb-4">
                  <img src="./images/three.svg" alt="three" />
                </div>
                <p className="desc">
                  Set the campaign live! Either by yourself or have your
                  dedicated campaign manager do it for you. We’ll be able to
                  help out with A/B tests, optimization and planning.
                </p>
              </div>
            </div>
          </div>
        </section>

        <div className="premium-support-section  pt-3 ">
          <div className="container">
            <div className="premium-support-title">
              High Performance - <span>Easy Setup</span> - Premium Support
            </div>
            <Link href="#" className="btn btn-primary">
              GET STARTED
            </Link>
          </div>
        </div>
      </main>
    </Layout>
  );
}
