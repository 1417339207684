import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const BrandSlider = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  const brandImages = [
    "./images/brand/adidas.png",
    "./images/brand/air-india.png",
    "./images/brand/clarkas.png",
    "./images/brand/danone.png",
    "./images/brand/fitbit.png",
    "./images/brand/lg.png",
    
  ];


  // Reverse the order of brandImages
  const reversedBrandImages = [
    "./images/brand/loreal.png",
    "./images/brand/parisa-wang.png",
    "./images/brand/pngwing-5.png",
    "./images/brand/rolex.png",
    "./images/brand/samsung.png",
    "./images/brand/subway.png",
    "./images/brand/tiffany.png",
  ];

  return (
    <div className="brand-slider">
      {/* Slider with images in descending order */}
      <Slider {...settings}>
        {reversedBrandImages.map((image, index) => (
          <div className="brand-logo" key={index}>
            <img src={image} alt={`Brand ${index + 1}`} />
          </div>
        ))}
      </Slider>

      {/* Slider with images in original order */}
      <Slider {...settings}>
        {brandImages.map((image, index) => (
          <div className="brand-logo" key={index}>
            <img src={image} alt={`Brand ${index + 1}`} />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default BrandSlider;
