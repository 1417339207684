import React, { useEffect, useState } from "react";
import Layout from "../components/Layout";
import { Helmet } from "react-helmet";
import Breadcumb from "../components/Breadcumb";

export default function TermsCondition(props) {
  useEffect(() => {
    props.setProgress(100);
  }, []);

  
  return (
    <Layout noClients={true}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Future Flix | Terms & Condition </title>
      </Helmet>
      <main class="main policy-page">
        <Breadcumb title="Terms of Use" desc="" />
        <div class="container py-4">
             
        <p>The subsequent terms of usage, or any future modifications thereof, (referred to as the "Terms of Use") oversee your entrance to and utilization of:</p>

        <ul>
            <li>the Future Flix Media website situated at <a href="http://www.futureflixmedia.com">www.futureflixmedia.com</a> (referred to as the "Website");</li>
            <li>the Future Flix Media online platform (and any application software ("Application")) facilitating the connection of individuals offering to create promotional online media content (each identified as an "Influencer") and Campaign Initiators (as defined below); and</li>
            <li>all other services provided by Future Flix Media, as outlined on the Website, collectively referred to as the "Platform."</li>
        </ul>

        <p>These Terms of Use constitute an agreement between FUTURE FLIX MARKETING MANAGEMENT L.L.C, a limited liability company established under License number 941790, Dubai, United Arab Emirates (referred to as "Future Flix Media," "us," "we," "our") and you, forming the sole agreement between us. By generating a User Account and/or by gaining access to or employing (the term "utilize" when mentioned herein in relation to the Platform signifies access or use, and employing shall possess a corresponding meaning) the Platform or underlying services, you are consenting to and concurring with these Terms of Use, including any future modifications. If you are disinclined to be bound by these Terms of Use, you should abstain from agreeing or establishing a User Account, and you should refrain from utilizing the Platform. If you are in the process of creating a User Account, accessing, or using the Platform on behalf of another individual or a corporate entity, or if you are functioning as an agent, you assert and affirm that you possess the authority to legally bind such an individual or entity to these Terms of Use. It is your sole obligation to guarantee that the Terms of Use comply with pertinent laws, rules, and regulations that apply to you.</p>

        <p>The term "you" pertains to the individual or entity browsing the Platform, exploring or otherwise engaging with the Platform, or communicating with content creators or Influencers registered with Future Flix Media or communicating with individuals or businesses registered with Future Flix Media (each regarded as a "Campaign Initiator") for the objective of generating promotional online media content for the products or services of Campaign Initiators (each identified as a "Campaign"). Depending on the context, the term "you" might pertain to the Influencer, Campaign Initiator, or both.</p>

        <h2>Platform Services</h2>

        <p>The Platform is provided exclusively (defined as the "Permitted Use") to:</p>

        <ul>
            <li>assist in collecting information about diverse Campaign opportunities and Campaign Initiators or Influencers available on the Platform, encompassing profiles, price ranges, Campaign descriptions, or Influencer videos (each known as a "Campaign Initiator Profile" or "Influencer Profile" as applicable);</li>
            <li>enable you to submit information regarding yourself and your Campaign request or respond to any Campaign opportunities;</li>
            <li>post Submissions or evaluations of Campaign Initiators or Influencers;</li>
            <li>facilitate communication with Campaign Initiators or Influencers with the goal of establishing a Campaign Agreement; and</li>
            <li>facilitate the transfer of payments from Campaign Initiators to Influencers in accordance with the Campaign Agreement (together referred to as the "Platform Services").</li>
        </ul>

        <p>The Platform empowers content creators or Influencers to, among other activities, interact with advertisers, marketers, or their representatives for the formation and dissemination of content produced by Influencers.</p>

        <p>You concur that you bear full responsibility for all interactions between you and any Influencer or Campaign Initiator (as relevant) through the Platform. Your decision to share your data and information (which may encompass personally identifiable information) with Campaign Initiators or Influencers, or your solicitation or acceptance of a Campaign, will be regarded as your positive "opt-in" for the divulgence by Future Flix Media of your data and information (which may encompass personally identifiable information) to that Campaign Initiator or Influencer.</p>

        <h2>Alterations to these Terms of Use and Platform</h2>

        <p>Unless restricted by pertinent law, Future Flix Media maintains the right to revise these Terms of Use at any time without prior notice. The continuance of your access to or utilization of the Platform following any changes to these Terms of Use signifies your acceptance of such adjustments. It is your responsibility to periodically review these Terms of Use.</p>

        <p>Future Flix Media retains the right to modify any details, materials, or content (comprising, though not confined to, price, features, availability of Campaign Initiators or Influencers, Campaign Initiator Profiles or Influencer Profiles, varieties of Campaigns, and assessments of Campaigns and Campaign Initiators or Influencers) encompassing designs, intellectual property rights, infrastructure graphics, images, illustrations, software, artwork, video, music, sound, names, terms, titles, expressions, logos, and marks displayed or provided on or through the Platform (termed as the "Content") at any time and intermittently, without prior notice.</p>

        <h2>Utilization of the Platform</h2>

        <p>As a prerequisite for employing the Platform, you affirm, warrant, and commit that:</p>

        <ul>
            <li>you have attained the legal age of majority in your jurisdiction of residence and are at least 18 years old;</li>
            <li>you possess the legal capacity to establish a binding legal commitment;</li>
            <li>you shall utilize the Platform in accordance with these Terms of Use; and</li>
            <li>all information provided by you on the Platform is accurate, truthful, current, complete, and not misleading.</li>
        </ul>

        <p>Future Flix Media reserves the right, at its sole discretion, to refuse access to the Platform or the services it provides to anyone, at any moment, and for any rationale, which may encompass violation of these Terms of Use. Should Future Flix Media request it, you must promptly halt any such access or utilization.</p>

        <h2>License Permissions</h2>

        <p>Subject to these Terms of Use, Future Flix Media grants you a personal, non-transferable, non-exclusive, and revocable license throughout the stated term to enable you to access and employ the Platform in accordance with these Terms of Use. The Platform, together with the databases, software, hardware, and other technological aspects employed by or on behalf of Future Flix Media to operate the Platform, along with its structure, arrangement, and underlying data, information, and software code, are considered valuable proprietary information of Future Flix Media. The Platform or any part thereof may not be duplicated, replicated, copied, reverse-engineered, downloaded, sold, resold, visited, or otherwise exploited for commercial purposes without the express written consent of Future Flix Media. You are prohibited from enclosing any trademark, logo, or other proprietary information (including text, images, page layout, and form) of Future Flix Media using framing methods or similar techniques without our explicit written authorization. Usage of meta tags or any other "hidden text" that employs Future Flix Media's name or trademarks without our explicit written consent is also prohibited. Unauthorized usage will result in the termination of the license granted by Future Flix Media.</p>

        <p>By adhering to these Terms of Use, you extend to Future Flix Media an everlasting, transferrable, irrevocable, royalty-free, fully paid-up, global, and completely sub-licensable license to access, amass, store, and utilize any Submissions, content, data, information, records, and files that:</p>

        <ul>
            <li>you create, upload, transmit to, or input into the Platform; or</li>
            <li>we acquire from your local computer system or from third parties with your permission (unless otherwise specified in the Terms of Use or Privacy Policy), and this also encompasses all outcomes derived from processing such data, including compilations and derivative works, solely for the purposes of:</li>
            <ul>
                <li>furnishing the Platform Services;</li>
                <li>conforming to applicable legal requirements; and</li>
                <li>Future Flix Media's rational audit and data retention policies.</li>
            </ul>
        </ul>

        <p>Furthermore, we reserve the right to unveil aggregated user statistics to elucidate our services to existing and potential business associates, as well as to other lawful parties for legitimate objectives.</p>

        <p>Specific content might involve or be based upon data, information, or content sourced from Campaign Initiators, Influencers, or other autonomous third-party content providers ("Third Party Content"). Future Flix Media has not confirmed the accuracy of, and shall not be held accountable for any errors or omissions in, any Third Party Content offered via the Platform. Apart from the provisions outlined in these Terms of Use, you shall not be granted any licenses or rights to any Content, or any intellectual property rights connected to it or resulting from it.</p>

<h2>User Profile</h2>

<p>To access the services, you will need to successfully create a user profile (referred to as the "User Profile") using the provided interfaces on the Platform. It is your responsibility to maintain the security of your User Profile information and refrain from sharing it with others. You are also prohibited from collecting personal data of other Brand Ripplr users, including account names. You understand that the User Profile is intended solely for the legitimate management of campaigns and the procurement of Platform Services. By establishing a User Profile, you affirm, assure, and commit that the information you provide to Future Flix Media is accurate, truthful, complete, and not deceptive. You are obligated to ensure that your registration details are accurate and up-to-date. Failing to do so will be considered a violation of these Terms of Use, which might lead to the immediate termination of your User Profile. Future Flix Media reserves the right to deactivate any User Profile granted to you at any time and at its sole discretion. If access to your issued User Profile is deactivated, you might lose access to Future Flix Media, your account details, and any associated campaigns.</p>

<p>You are responsible for safeguarding your password used to access the User Profile and for all activities undertaken under your password, whether on our service or through a third-party service. You bear full responsibility for all liabilities and damages arising from the use of your User Profile, whether lawful or unlawful. Any transactions carried out through your User Profile will be considered your lawful actions. If you add other users to your User Profile, (i) such users should only be authorized representatives of your company or respective client, and (ii) you will be solely accountable for their actions within the User Profile, ensuring compliance with these Terms of Use.</p>

<p>You agree not to share your password with any third party. If you become aware of any security breach or unauthorized use of your User Profile, you must inform us immediately. Neglecting to do so will be deemed a significant violation of these Terms of Use.</p>

<h2>Duration and Termination</h2>

<p>The Terms of Use and Privacy Policy are effective from the earlier of the date you initially download or install an Application, access or utilize the Platform or Content, or demonstrate your acceptance by ticking a box that signifies your agreement to these Terms of Use. This agreement will persist until terminated as outlined herein.</p>

<p>We retain the right to suspend or terminate your access to the Platform, Platform Services, or any other available services on our Website at our discretion and without prior notification. You can cancel your User Profile at any time by sending an email to <a href="mailto:info@futureflixmedia.com">info@futureflixmedia.com</a>.</p>

<p>Upon termination or expiration of these Terms of Use for any reason:</p>

<ul>
    <li>all granted rights and subscriptions will terminate;</li>
    <li>your use and access to the Platform and all Content will cease immediately;</li>
    <li>any Applications downloaded or installed before termination must be promptly deleted; and</li>
    <li>we reserve the right to delete your User Profile and any campaign-related content held by Future Flix Media at any time.</li>
</ul>

<p>Certain provisions of these Terms of Use will persist even after termination, including, but not limited to, ownership rights, warranty disclaimers, licensing permissions, liability limitations, and dispute resolution clauses.</p>

<h2>Campaign Collaboration with Campaign Initiators</h2>

<p>The Platform enables you to access Campaign Initiator and Influencer profiles (as applicable) that have registered on our Platform and agreed to allow you to view their respective Campaign Initiator or Influencer Profiles.</p>
<p>In cases where a Campaign Initiator selects an Influencer for a Campaign using the Platform, you have the opportunity to establish a separate agreement with that Campaign Initiator/Influencer based on mutually agreed terms and conditions ("Campaign Agreement"). Such a Campaign Agreement might include:</p>

    <ul>
        <li>a detailed outline of the services the Influencer will provide to the Campaign Initiator, including the timing and approval process for posting;</li>
        <li>the payment arrangement; and</li>
        <li>any other agreed-upon terms.</li>
    </ul>

    <p>It's important to understand that services provided are between the Influencer and the Campaign Initiator, and Future Flix Media is not a party to, and holds no responsibility for, the performance of either the Influencer or the Campaign Initiator under any Campaign Agreement. We will facilitate payment transmission as directed by the Campaign Initiator in accordance with these Terms of Use. Furthermore, any content submitted or generated through the Platform as part of a Campaign Agreement and approved by a Campaign Initiator will remain publicly accessible on your specified social media account(s) for at least six (6) months. It should be noted that Future Flix Media does not offer any guarantees or warranties regarding Campaign Initiators or Campaign Agreements. Both the Campaign Initiator and Influencer must uphold good faith throughout the negotiation and fulfillment of obligations under each Campaign Agreement.</p>

    <p>To ensure transparency, Campaign Initiators are encouraged to have Influencers disclose the sponsored nature of reviews for Campaign Initiator products during a Campaign.</p>

    <h2>Facilitated Payment Process</h2>

    <p>In instances where Future Flix Media assists in transferring payments from Campaign Initiators to Influencers for a Campaign Agreement (referred to as "Facilitated Payment"), we may withhold any amounts payable to us by the Campaign Initiator from the Facilitated Payment. It's important to note that Future Flix Media does not act as a trustee or fiduciary for Facilitated Payments or Campaign Agreements. We maintain the right to hold a Facilitated Payment if we suspect potential violations of applicable laws (such as fraud or money laundering) or if required by law enforcement cooperation.</p>

    <p>Influencers and Campaign Initiators hereby acknowledge and accept the following:</p>

    <p><strong>For Influencers:</strong></p>

    <ul>
        <li>You must not modify the agreed-upon remuneration (referred to as "Fee") with the Campaign Initiator during a Campaign.</li>
        <li>Should you fail to post approved Submissions on your designated social media account at the agreed-upon date and time (within a three-hour grace period based on your time zone), you will not be eligible to receive the Fee.</li>
    </ul>

    <p>Removing a published post before the specified six-month period or any other agreed-upon date subjects you to repaying the Fee received, along with additional charges equivalent to at least the Fee amount. These charges, along with any incurred costs and expenses by the Campaign Initiator and Future Flix Media, will become immediately payable to Future Flix Media.</p>

    <p>Your earned Fee from a Campaign, reflected in your User Account's Wallet section, will be available after all posts stipulated by the Campaign Initiator are published and linked on the Platform under the Campaign dashboard. You can withdraw this balance from your wallet between 14 to 45 days after the Campaign's publication and link sharing.</p>

    <p>You must withdraw the entire available balance in your Wallet when requesting Fee withdrawal. Partial withdrawals or transfers are not supported. A minimum of USD 100 in your Wallet is required to request withdrawal. All transfer expenses (e.g., bank transfer fees, PayPal fees) will be deducted from the Fee and borne by you.</p>

    <p>After an approved Submission is posted, you agree not to make any other posts for a specific period (12 hours for personal content, 48 hours for commercial content) without prior written approval from both Future Flix Media and the Campaign Initiator.</p>

    <p>If a Campaign is canceled prior to an approved Submission, you will not be entitled to receive the Fee.</p>

    <p>If a Campaign is canceled after an approved Submission but before your post's publication and link sharing, you will not be entitled to receive the Fee.</p>

    <p>If a Campaign is canceled after an approved Submission and after your post's publication and link sharing, you will be entitled to receive the Fee.</p>

    <p><strong>For Campaign Initiators:</strong></p>

    <ul>
        <li>You must not modify the agreed-upon remuneration with the Influencer during a Campaign.</li>
        <li>If a Campaign is canceled after an approved Submission and after the Fee payment but before the Influencer's post publication and link sharing, the Fee, including compensation for Platform Services payable to Future Flix Media, will not be refunded.</li>
    </ul>

    <p>Future Flix Media does not issue refunds. If you believe a refund is necessary, contact <a href="mailto:info@futureflixmedia.com">info@futureflixmedia.com</a>. While we will review the request, we cannot guarantee approval.</p>

    <p>Campaign Initiators have seven working days to raise concerns regarding a published post. Detailed explanations must accompany such concerns. Future Flix Media will consider these concerns and attempt to suggest appropriate solutions, without being obligated to do so.</p>

    <p>Campaign Initiators are responsible for the payment of all applicable taxes, fees, and duties related to the Fees, as well as any other charges. Value-added tax will be charged separately.</p>

    <h2>Six-Month Exclusivity Clause</h2>

    <p>Both Campaign Initiators and Influencers recognize and agree that for a duration of six (6) months starting from either:</p>

    <ul>
        <li>the last interaction date with a Campaign Initiator or Influencer (whichever is applicable) regarding a Campaign, whether through the Platform or otherwise, or</li>
        <li>the Campaign Agreement's execution date with a Campaign Initiator or Influencer (whichever is applicable) (referred to as the "Commencement Date"),</li>
    </ul>

    <p>neither you nor your affiliates, agents, directors, employees, or subcontractors will enter into an agreement, directly or indirectly, with the aforementioned Campaign Initiator or Influencer (whichever is applicable) for promotional media content creation. This restriction is subject to the following exceptions:</p>

    <ul>
        <li>The agreement pertains to a Campaign Agreement in which Future Flix Media will receive an agreed Fee.</li>
        <li>You were in good-faith negotiations with the Campaign Initiator or Influencer (whichever is applicable) regarding the creation of promotional media content on or before the Commencement Date.</li>
        <li>You are renewing a pre-existing agreement with the Campaign Initiator or Influencer (whichever is applicable) that expired after the Commencement Date.</li>
    </ul>

    <p>You commit not to contact, discuss, solicit, or enter into arrangements beyond the stipulated exceptions directly or indirectly with the Campaign Initiator or Influencer (whichever is applicable) or any of their representatives except through the Platform. Failure to adhere to this commitment may result in the cancellation of your User Account and other appropriate actions as determined by Future Flix Media.</p>

    <h2>Submission of Content, Reviews, and Comments</h2>
    <p>By submitting content, reviews, questions, comments, generated promotional online media content, or similar information (collectively referred to as "Submissions") to the Platform or Future Flix Media, you:</p>

    <ul>
        <li>Grant Future Flix Media and its affiliates a worldwide, non-exclusive, royalty-free, perpetual, transferable, irrevocable, and fully sub-licensable right to use, modify, adapt, distribute, reproduce, translate, publish, publicly display, and perform such Submissions across the globe in any media, known or developed in the future. You also grant Future Flix Media permission to use your name in connection with the Submission.</li>
        <li>Acknowledge that Future Flix Media may choose, but is not obliged to, attribute your Submissions (such as listing your name and city on a submitted Campaign review) at our discretion. Additionally, Submissions may be shared with other Campaign Initiators and users of the Platform.</li>
        <li>Recognize that Submissions are neither confidential nor proprietary.</li>
        <li>Understand that Future Flix Media has the right to use, publish, or remove Submissions without notice.</li>
        <li>Agree not to reuse, share with a third party, or use Submissions or content similar to Submissions for purposes other than those stated herein without prior written approval from Future Flix Media.</li>
    </ul>

    <p>In addition to this, both Influencers and Campaign Initiators are required to maintain Submissions submitted or generated through the Platform as part of a Campaign Agreement and approved by a Campaign Initiator accessible on their social media account(s) for at least six (6) months, or for any other duration specified in the Campaign Agreement. Any breach of this obligation may result in the forfeiture of the Fee and additional charges payable to Future Flix Media and the Campaign Initiator.</p>

    <p>Campaign Initiators recognize that any Submissions or approved promotional online media content generated by Influencers remain the property of the Influencers. However, Campaign Initiators are granted the right to use Submissions as part of an advertisement campaign on social media, provided proper attribution is given to the Influencer. For advertisements outside of social media, Campaign Initiators must obtain Influencer's approval before using Submissions.</p>

    <p>All data regarding Influencers and Campaign performance on the Platform is periodically updated. Real-time statistics and data can be found on the respective social media networks.</p>

    <h3>Limitation of Liability</h3>

    <p>Future Flix Media assumes no responsibility and accepts no liability for any Submissions posted or submitted. Future Flix Media is under no obligation to publish Submissions and retains the absolute discretion to determine which Submissions are made available on the Platform. If you do not agree with these terms, kindly refrain from providing any Submissions.</p>

    <p>You bear full responsibility for the content of Submissions, and you hereby declare and assure that:</p>

    <ul>
        <li>You possess all the necessary rights to the content submitted on the Platform, including but not limited to reviews posted, designs, graphics, images, illustrations, software, artworks, videos, music, sounds, names, words, titles, phrases, logos, and marks that may be displayed on an ongoing basis.</li>
        <li>You have procured all required licenses, permits, or approvals relevant to your jurisdiction.</li>
        <li>Your utilization of the Platform Services and Platform, as well as each of your Submissions: A) does not violate the rights of any other individual or entity; and B) adheres to all applicable laws, regulations, codes, and standards. This includes compliance with advertising standards and regulations related to sponsored or advertorial content that might apply to you in your jurisdiction. Such responsibilities encompass ensuring that any Submission includes necessary hashtag requirements as mandated by applicable advertising standards and regulations.</li>
    </ul>

    <h3>Ownership</h3>

    <p>All Content, including but not limited to designs, intellectual property rights (IPR), graphics, images, illustrations, software, artworks, videos, music, sounds, names, words, titles, phrases, logos, and marks displayed on the Platform, are either owned by or licensed to Future Flix Media. These materials are safeguarded by copyright, trademark, and other intellectual property laws.</p>

    <p>Future Flix Media expressly retains all rights in the Platform and all materials provided by Future Flix Media, except those specifically granted to you. You acknowledge that all rights, titles, and interests in the Platform, all materials provided by Future Flix Media in connection with these Terms of Use (including the Content), and any updates, improvements, additions, adaptations, translations, customizations, or derivative works thereof, along with all intellectual property rights, remain with Future Flix Media (or applicable third-party suppliers). The Platform, materials, and content provided by Future Flix Media are licensed to you and not "sold." Your right is limited to accessing the Platform and Content according to these Terms of Use. All Content and materials provided by Future Flix Media on the Website are copyrighted ©2023 Future Flix Media, LLC.</p>

         </div>
        
      </main>
    </Layout>
  );
}
