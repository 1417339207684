import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { BsInstagram, BsFacebook, BsLinkedin } from "react-icons/bs";
import Select from "react-select";
import axios from "axios";
import BrandSlider from "./BrandSlider";
import { toast } from 'react-toastify';

export default function Footer(props) {
  const [countries, setCountries] = useState([]);
  const [defaultCountry, setDefaultCountry] = useState(null);
  const { noFooterForm, noClients } = props;
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    role: "",
    number: "",
    message: "",
  });

  useEffect(() => {
    fetch("https://restcountries.com/v3.1/all")
      .then((response) => response.json())
      .then((data) => {
        setCountries(data);
        const uaeCountry = data.find((country) => country.cca2 === "AE");
        if (uaeCountry) {
          setDefaultCountry({
            value: uaeCountry.cca2,
            label: (
              <>
                <img
                  style={{ height: "20px", width: "20px" }}
                  src={uaeCountry.flags.png}
                />{" "}
                {uaeCountry.cca2}
              </>
            ),
            icon: uaeCountry.flags.png,
          });
        }
      })
      .catch((error) => console.error("Error fetching country data:", error));
  }, []);
  const countryOptions = countries.map((country) => ({
    value: country.cca2,
    label: (
      <>
        <img
          style={{ height: "20px", width: "20px" }}
          src={country.flags.png}
        />{" "}
        {country.cca2}
      </>
    ),
    icon: country.flags.png,
  }));

  const customOption = ({ value, label, icon }) => (
    <div className="custom-option">
      <img src={icon} alt="" className="flag-icon" />
      {label}
    </div>
  );

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    
    console.log('formData',{...formData,country_code: defaultCountry.value});
    try {
      // Make an API request using axios
      const response = await axios.post("https://www.futureflixmedia.com/api/v1/Contact/insert-contact.php", {...formData,country_code: defaultCountry.value});
      
      // Handle the response if needed (e.g., show a success message)
      console.log("API response:", response);

      toast.success(<><b>{`Thank you for contacting us.`}</b> <br/>{`Our representative will contact you shortly`}</>)
      // Clear the form after successful submission
      setFormData({
        name: "",
        email: "",
        number: "",
        role: "",
        message: "",
      });
    } catch (error) {
      // Handle errors if the API request fails
      console.error("API request error:", error);
      toast.error(<><b>{`Something went wrong.`}</b> <br/>{error}</>)
    }
  };
  return (
    <>
    {!noClients && <section className="section sec-space team-section" style={{padding:"50px 0px"}}> 
    <div className="container">
        <div className="text-center">
            <div className="heading-center mb-md-5">Trusted <span className="text-primary">Clients</span></div>
        </div>
         <BrandSlider />
    </div>
  </section>}
    <footer className="footer">
      <div className="footer-bg-img"></div>
      <div className="footer-bg-img tringle"></div>
      <div className="container">
        <div className="row">
          <div className="col-md-6 pe-md-5">
            {!noFooterForm && (
              <div className="conectwith-us">
                <h2 className="footer-heading">
                  <span className="text-primary">Connect</span> With Us
                </h2>
                <form onSubmit={handleFormSubmit}>
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="form-group">
                        <label for="name" className="sr-only"></label>
                        <input
                          type="text"
                          className="form-control"
                          id="name"
                          placeholder="Name*"
                          value={formData.name}
                          onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <label for="email" className="sr-only"></label>
                        <input
                          type="email"
                          className="form-control"
                          id="email"
                          placeholder="Email*"
                          value={formData.email}
                          onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <label for="number" className="sr-only"></label>
                        {/* <input
                        type="number"
                        className="form-control"
                        id="number"
                        placeholder="Phone Number*"
                      /> */}
                        {/* <Select
                  options={countryOptions}
                  optionComponent={customOption}
                  valueComponent={customOption}
                /> */}
                        <div class="input-group mb-3">
                          <span class="input-group-text" id="basic-addon1">
                            <div
                              class="flag-dropdown"
                              style={{ width: "100px" }}
                            >
                              <Select
                               components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
                                options={countryOptions}
                                optionComponent={customOption}
                                valueComponent={customOption}
                                onChange={setDefaultCountry}
                                value={defaultCountry}
                              />
                            </div>
                          </span>
                          <input
                            type="number"
                            className="form-control"
                            id="number"
                            placeholder="Phone Number*"
                            value={formData.number}
                            onChange={(e) => setFormData({ ...formData, number: e.target.value })}
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <label for="text01" className="sr-only"></label>
                        <select
                          className="form-control"
                          id="text01"
                          placeholder="Select*"
                          value={formData.role}
                          onChange={(e) => setFormData({ ...formData, role: e.target.value })}
                          required
                        >
                          <option value="">For*</option>
                          <option value="Advertiser">Advertiser</option>
                          <option value="Publisher">Publisher</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <label for="message" className="sr-only d-none">
                      Message
                    </label>
                    <textarea
                      id="message"
                      name="message"
                      rows="6"
                      cols="50"
                      className="form-control"
                      placeholder="Message*"
                      value={formData.message}
                       onChange={(e) => setFormData({ ...formData, message: e.target.value })}
                       required
                    >
                      
                    </textarea>
                  </div>

                  <div className="btn-wrap">
                    <button className="btn-primary btn" type="submit">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            )}
          </div>
          <div className="col-md-6">
            <div className="footer-info">
              <ul className="list-unstyled connet-links">
                <li className="item">
                  <span className="label mb-2 text-primary">Office:</span>
                  1707, 17th Floor, Indigo Icon, Cluster F, Jumeirah Lake
                  Towers, Dubai
                </li>
                <li className="item">
                  <span className="label mb-2 text-primary">Phone:</span>
                  <a href="tel:+97145298257" target="_blank" title="Call">
                    +971 4 529 8257
                  </a>
                  <a href="tel:+971502232118" target="_blank" title="Call">
                    +971 50 223 2118
                  </a>
                </li>
                <li className="item">
                  <span className="label mb-2 text-primary">Email:</span>
                  <a
                    href="mailto:info@futureflixmedia.com"
                    target="_blank"
                    title="Mail"
                  >
                    info@futureflixmedia.com
                  </a>
                </li>
              </ul>
            </div>

            <ul className="list-unstyled p-0  d-flex align-items-center justify-content-center justify-content-lg-end mb-4 footer-social">
              <li className="item">
                <a
                  href="https://www.linkedin.com/company/future-flix-media/"
                  target="_blank"
                  title="Linkdin"
                >
                  <span className="icon socialicon">
                    <BsLinkedin></BsLinkedin>
                  </span>
                </a>
              </li>
              <li className="item ">
                <a
                  href="https://www.instagram.com/futureflixmedia/"
                  target="_blank"
                  title="Instagram"
                >
                  <span className="icon socialicon">
                    <BsInstagram></BsInstagram>
                  </span>
                </a>
              </li>
              <li className="item">
                <a
                  href="https://www.facebook.com/futureflixmedia"
                  target="_blank"
                  title="Facebook"
                >
                  <span className="icon socialicon">
                    <BsFacebook></BsFacebook>
                  </span>
                </a>
              </li>
            </ul>
            <ul className="list-unstyled p-0 footer-links d-flex align-items-center justify-content-center justify-content-lg-end mb-2">
              <li className="item">
                <a
                  href="/who-we-are"
                  className="f-links"
                  target="_self"
                  title="About US"
                >
                  About Us
                </a>
              </li>
              <li className="item">
                <a
                  href="/privacy-policy"
                  className="f-links"
                  target="_self"
                  title="Privacy Policy "
                >
                  Privacy Policy
                </a>
              </li>
              <li className="item">
                <a href="/terms-condition" className="f-links" target="_self" title="Declimer">
                  Terms & Condition
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
    </>
  );
}
