import React, {useEffect} from "react";
import Layout from "../components/Layout";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

export default function Retargeting(props) {
    useEffect(() => {
        props.setProgress(100)
    }, []);
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Future Flix | Retargeting </title>
      </Helmet>
      <main className="main retargeting-page">
        <div className="ads-section">
          <div className="container">
            <div className="ads">
              <div className="ads-title">Retargeting Ads</div>
              <p className="ads-des">
                Win back customers that have shown interest but left your site
                without converting.
              </p>
            </div>
          </div>
        </div>

        <div className="demo-section">
          <div className="container">
            <div className="demo-title">Smart Retargeting</div>
            <div className="row">
              <div className="col-lg-6">
                <p className="demo-des">
                  Supercharge your advertising performance with retargeting and
                  reach website visitors on thousands of publishers. Match2One's
                  AI is built to meticulously track and target your website
                  visitors, allowing you to deliver laser-focused relevance that
                  captivate those who have already shown genuine interest in
                  your brand or products. As the conversion potential
                  skyrockets, our AI dynamically adjusts bids to secure prime
                  advertising placements and maximize results.
                </p>
                <p className="demo-des">
                  Whether it's providing additional information or extending a
                  special offer, retargeting keeps your brand at the forefront
                  of their minds.
                </p>
                <p className="demo-des">
                  Don't let any potential customer slip away - use a proven
                  strategy that delivers results, empowering you to leave their
                  competitors in the dust.
                </p>
                <Link href="#" className="btn btn-primary">
                  book demo
                </Link>
              </div>
              <div className="col-lg-6 demo-img">
                <img
                  src="./images/leading-the-way-for-contextual-advertising.png"
                  alt="leading-the-way-for-contextual-advertising"
                  title="leading-the-way-for-contextual-advertising"
                  width="632"
                  height="384"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="retargeting-steps-section">
          <div className="container">
            <div className="retargeting-step-title">Retargeting steps</div>
            <div className="retargeting-steps">
              <ul className="m-0 p-0">
                <li>
                  <div className="retargeting-steps-img">
                    <img
                      src="./images/user-converts-customer-on-your-site.png"
                      alt="user-converts-customer-on-your-site"
                      title="user-converts-customer-on-your-site"
                      width="133"
                      height="97"
                    />
                  </div>
                  <div className="retargeting-steps-dec">User visits your site</div>
                </li>
                <li>
                  <div className="retargeting-steps-img">
                    <img
                      src="./images/your-capture-users-interest.png"
                      alt="your-capture-users-interest"
                      title="your-capture-users-interest"
                      width="130"
                      height="96"
                    />
                  </div>
                  <div className="retargeting-steps-dec">User leaves your site</div>
                </li>
                <li>
                  <div className="retargeting-steps-img">
                    <img
                      src="./images/browsing-online-user-sees-your-ads-other-websites.png"
                      alt="browsing-online-user-sees-your-ads-other-websites"
                      title="browsing-online-user-sees-your-ads-other-websites"
                      width="131"
                      height="93"
                    />
                  </div>
                  <div className="retargeting-steps-dec">
                    Browsing online, user sees your ads on other websites
                  </div>
                </li>
                <li>
                  <div className="retargeting-steps-img">
                    <img
                      src="./images/user-leaves.png"
                      alt="user-leaves"
                      title="user-leaves"
                      width="124"
                      height="107"
                    />
                  </div>
                  <div className="retargeting-steps-dec">
                    Your ad capture the users interest
                  </div>
                </li>
                <li>
                  <div className="retargeting-steps-img">
                    <img
                      src="./images/user-visits.png"
                      alt="user-visits"
                      title="user-visits"
                      width="117"
                      height="93"
                    />
                  </div>
                  <div className="retargeting-steps-dec">
                    User converts to a customer on your site
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="retargeting-sales-section">
          <div className="container">
            <div className="retargeting-sales">
              <div className="retargeting-sales-title">
                Retargeting in your sales funnel
              </div>
              <div className="sales">
                <div className="sales-left-col">
                  <div className="sales-left-top">
                    LOOKALIKE MODELLING CONTEXTUAL TARGETING BEHAVIOURAL
                    TARGETING DEMOGRAPHIC TARGETING
                  </div>
                  <div className="sales-left-bottom">RETARGETING</div>
                </div>
                <div className="sales-funnenl-img">
                  <img
                    src="./images/sales-funnenl.png"
                    alt="sales-funnenl"
                    title="sales-funnenl"
                    width="480"
                    height="412"
                  />
                </div>
                <div className="sales-right-col">
                  <div className="sales-right-top">
                    <div className="sales-right-title">DRIVE AWARENESS</div>
                    <p className="sales-right-des">
                      Elevate your brand and reach new audiences on a
                      large-scale
                    </p>
                  </div>
                  <div className="sales-right-middle">
                    <div className="sales-right-title">ACQUIRE NEW CUSTOMERS</div>
                    <p className="sales-right-des">
                      Drive specific audience segments to your website at the
                      right moments, with the right message, in the right
                      context
                    </p>
                  </div>
                  <div className="sales-right-bottom">
                    <div className="sales-right-title">CONVERT ENGAGED USERS</div>
                    <p className="sales-right-des">
                      Retarget applicants on their journey across devices with
                      application focused call to acions
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="prospecting-section border-top">
          <div className="container">
            <div className="row">
              <div className="prospecting-title">
                Why do I need both Prospecting
                <span className="d-block">and Retargeting?</span>
              </div>
              <div className="col-lg-6">
                <p className="prospecting-des">
                  To achieve the best results, we recommend a combination of
                  Prospecting, Contextual, and Retargeting. This will help you
                  find new customers Prospecting, and get back customers that
                  have visited before Retargeting, but left your site before
                  converting. In this way, you’ll cover both the top and bottom
                  of your online Sales Funnel.
                </p>
              </div>
              <div className="col-lg-6">
                <div className="prospecting-benefits">
                  <div className="prospecting-benefits-title">
                    What are the benefits of Retargeting ads?
                  </div>
                  <ul className="p-0">
                    <li>Direct, measurable ROI</li>
                    <li>Measurable sales growth</li>
                    <li>High conversion rate</li>
                  </ul>
                  <Link href="#" className="btn btn-primary">
                    book demo
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="customer-success-section">
          <div className="container">
            <div className="customer-success-title">How do I plan my budget?</div>
            <p className="customer-success-des">
              For Retargeting campaigns there is a limit on how much you can
              spend based on your website traffic. For example, if your website
              has 1,000 visitors per day, you will normally only get $10-20 a
              day as a maximum media cost. With that in mind, we recommend
              setting high retargeting budget roofs, as they will automatically
              limit themselves.
            </p>
            <p className="customer-success-des mt-4 mb-4">
              Need some guidance? Our Customer Success Managers can help you
              with your specific campaign objectives and a recommended budget
              needed to achieve them.
            </p>
            <div className="customer-success-buttons">
              <Link href="#" className="btn btn-primary">
                book demo
              </Link>
              <Link href="#" className="btn btn-primary new-primary-button">
                book demo
              </Link>
            </div>
          </div>
        </div>

        <div className="premium-support-section">
          <div className="container">
            <div className="premium-support-title">
              High Performance - <span>Easy Setup</span> - Premium Support
            </div>
            <Link href="#" className="btn btn-primary">
              GET STARTED
            </Link>
          </div>
        </div>
      </main>
    </Layout>
  );
}
