import React, {useEffect} from "react";
import Layout from "../components/Layout";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

export default function ProspectingAds(props) {
    useEffect(() => {
        props.setProgress(100)
    }, []);
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Future Flix | Prospection Ads </title>
      </Helmet>
      <main class="main prospecting-ads">
     <div class="ads-section py-4">
        <div class="container">
          <div class="ads">
            <div class="ads-title">Retargeting Ads</div> 
            <p class="ads-des">
                Reach relevant audience with Future Prospecting Ads
            </p> 
          </div>
        </div>
      </div>

<section class="section"> 
    <div class="demo-section">
        <div class="container">
            <div class="demo-title mb-4">
                Discover Your Goldmine:  Prospecting  <br/> with  
                <span class="text-primary">Future Flix Media</span> AI!
            </div> 
            <div class="row">
            <div class="col-lg-6">
              <p class="demo-des">
                At Future Flix Media, we're all about finding new customers for your business, and we've got the magic of AI to make it happen. Our cutting-edge AI technology collects valuable user data and crafts a strategic optimization plan, honing in on the exact people who are most likely to be interested in what your business has to offer. From a treasure trove of user information, our AI makes brilliant decisions that ensure your brand shines bright and captures the hearts of your ideal audience. Embrace the future of prospecting and watch your business flourish like never before!
              </p>  
              <a href="#" class="btn btn-primary text-uppercase">Book Demo</a>
            </div>
            <div class="col-lg-6 demo-img">
              <img
                src="./images/prospection-work.png"
                alt="prospection-work" 
                width="610"
                height="472"
              />
            </div>
          </div>
        </div>
      </div>
</section>


<section class="section prospect-retargeting">
<div class="container">
    <h2 class="text-center ret-heading mb-lg-5"> Prospecting Ads Vs Retargeting ads? 
    </h2>
    <div class="row">
        <div class="col-lg-6">
            <div class="inner-content">
                <div class="icon">
                    <img src="./images/prospecting.svg" alt="prospecting" width="123" height="99" />
                </div>
                <div class="name">Prospecting</div>
                <p> Cutting-edge strategy showcases ads to eager, engaged audiences, converting them into loyal customers. Propel your business to new heights!
                    </p>

            </div>
        </div>
        <div class="col-lg-6">
            <div class="inner-content">
                <div class="icon">
                    <img src="./images/retiring.svg" alt="retiring" width="123" height="99" />
                </div>
                <div class="name">Retargeting</div>
                <p> Targeted ads for website visitors who didn't convert. Re-engage and transform potential customers into loyal advocates.</p>
            </div>
        </div>
    </div>
</div>
</section>

  {/* <div class="retargeting-sales-section">
    <div class="container">
      <div class="retargeting-sales">
        <div class="retargeting-sales-title mb-3">
            Prospecting in your marketing <br/> funnel:
        </div> 
        <p class="mb-3">from an internet user to your customer</p>
        <div class="sales mt-lg-5">
          <div class="sales-left-col">
            <div class="sales-left-top">
              LOOKALIKE MODELLING CONTEXTUAL TARGETING BEHAVIOURAL TARGETING
              DEMOGRAPHIC TARGETING
            </div>
            <div class="sales-left-bottom">RETARGETING</div>
          </div>
          <div class="sales-funnenl-img">
            <img
              src="./images/sales-funnenl.png"
              alt="sales-funnenl"
              title="sales-funnenl"
              width="480"
              height="412"
            />
          </div>
          <div class="sales-right-col">
            <div class="sales-right-top">
              <div class="sales-right-title">DRIVE AWARENESS</div>
              <p class="sales-right-des">
                Elevate your brand and reach new audiences on a large-scale
              </p>
            </div>
            <div class="sales-right-middle">
              <div class="sales-right-title">ACQUIRE NEW CUSTOMERS</div>
              <p class="sales-right-des">
                Drive specific audience segments to your website at the
                right moments, with the right message, in the right context
              </p>
            </div>
            <div class="sales-right-bottom">
              <div class="sales-right-title">CONVERT ENGAGED USERS</div>
              <p class="sales-right-des">
                Retarget applicants on their journey across devices with
                application focused call to acions
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>  */}

  <section class="section improve-time">
    <div class="prospecting-section border-top">
        <div class="container">
          <div class="row">
            <div class="col-12">
                <div class="prospecting-title"> 
                    How your <span class="text-primary">results</span> improve over time
                </div>
            </div>
            <div class="col-lg-6">
                <div class="image-wrap mb-4">
                    <img src="./images/chart.png" alt="chart" width="400" height="450" />     
                </div>  
            </div>
            <div class="col-lg-6">
              <div class="prospecting-benefits mt-lg-5 pt-lg-3">
                <div class="prospecting-benefits-title">
                    What are the benefits of Prospecting ads?
                </div> 
                <ul class="p-0"> 
                  <li> Vast Audience Reach</li>
                  <li>Brand Awareness & Engagement</li>
                  <li>Potential Lead Generation</li>
                </ul>
                <a href="#" class="btn btn-primary text-uppercase">Book Demo</a>
              </div>
            </div>
            <div class="col-12 mt-4">
                <p class="prospecting-des mb-lg-4">
                    During the learning phase, the goal is to teach the AI which tactics will work best for your particular campaign. This is done through broad targeting to gain insights on which websites and visitors are most likely to generate conversions for you.
                </p>
                <p class="prospecting-des mb-lg-4">  
                    We find the best audience based on factors like demographics, interests, and behavior, and then narrow down our targeting to reach the right people. Also, we keep track of important metrics like click-through rates, conversion rates, and cost per acquisition to understand how well the ads are doing. 
                </p>
                <p class="prospecting-des mb-lg-4"> 
                    By doing this, we gain valuable insights and can make smarter decisions to improve our ad performance over time.
                </p> 
            </div>
          </div>
        </div>
      </div>
  </section>

    <section class="section">
        <div class="customer-success-section">
            <div class="container">
              <div class="customer-success-title">Start Reaching the right audience!</div>
              <p class="customer-success-des mb-md-5 mb-3">
                Our advanced targeting and refined strategies will help you connect with the ideal audience, <br/> increasing your ad's impact and boosting your results
              </p> 
              <div class="customer-success-buttons">
                <a href="#" class="btn btn-primary text-uppercase">Book Demo</a>
                <a href="#" class="btn btn-primary new-primary-button text-uppercase">Call us now</a>
              </div>
            </div>
          </div>
    </section>

    <section class="section high-performance">
        <div class="premium-support-section">
            <div class="container">
              <div class="premium-support-title">
                High Performance - <span>Easy Setup</span> - Premium Support
              </div>
              <a href="#" class="btn btn-primary">GET STARTED</a>
            </div>
          </div>
    </section>

    </main>
    </Layout>
  );
}
