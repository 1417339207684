import React, { useEffect, useState } from "react";
import Layout from "../components/Layout";
import { Helmet } from "react-helmet";
import Breadcumb from "../components/Breadcumb";

export default function Privacy(props) {
  useEffect(() => {
    props.setProgress(100);
  }, []);

  
  return (
    <Layout noClients={true}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Future Flix | Privacy Policy </title>
      </Helmet>
      <main class="main policy-page">
        <Breadcumb title="Privacy Policy" desc="" />
        <div class="container py-4">
             
             <p>Welcome to  <a href="https://www.futureflixmedia.com"
                     target="_blank">Future Flix Media</a> , the "Website," operated by Future Flix Media.
                 This Privacy Policy, in conjunction with the Terms of Use, outlines the terms governing your usage of
                 the Website. We highly value your trust and privacy at <a href="https://www.futureflixmedia.com"
                     target="_blank"></a> Definitions in this Privacy Policy align with those in our Terms and Conditions
                 at https://www.futureflixmedia.com. This Privacy Policy provides comprehensive details about the collection,
                 storage, and utilization of your data by us.
                 Carefully review this Privacy Policy, as visiting our Website signifies your explicit consent to use and
                 disclose your personal information as per this policy.
                 If you disagree with the terms, please refrain from using or accessing our Website.
                 When the context requires, the terms "You," "Your," or "User" refer to any natural or legal entity who
                 either creates a membership account through this Website, agrees to access our Services, or engages with
                 our Website</p>
 
 
 
 
             <p>This Website, with the URL https://www.futureflixmedia.com, as well as its web pages, links, tools, and services,
                 is owned and operated by Future Flix Media, known as the "Company."
                 The Company is a legally incorporated entity under the Companies Act 1956, with its registered office
                 situated at <b>1707, 17th Floor, Indigo Icon, Cluster F, Jumeirah Lake Towers, Dubai.</b></p>
 
             <p>
                 We are publishing this Privacy Policy in accordance with:
 
             </p>
             <ul class="ps-0">
                 <li>
                     Section 43A of the Information Technology Act, 2000; and
 
                 </li>
                 <li>
                     Regulation 4 of the Information Technology (Reasonable Security Practices and Procedures and
                     Sensitive Personal Information) Rules, 2011.
                 </li>
             </ul>
 
 
             <p>
                 <b>General</b>
                 Rest assured, we uphold your privacy. Your personal information will not be sold, shared, or leased to
                 any external parties, nor will your email address or mobile number be utilized for unsolicited emails or
                 SMS.
                 Any communications from us will solely pertain to the provision of agreed-upon services, products, and
                 adherence to this Privacy Policy.
                 For general marketing purposes, we may send emails to users registered on our website or through other
                 media channels, which may include our website, merchants, advertisements, marketing campaigns, audio or
                 visual media, or websites.
                 We retain the authority to disclose your personal information to third parties only if legally required
                 to do so.
             </p>
 
             <b> Collection of Information</b>
             <p>
                 To effectively provide our services, we might gather various types of information, including:
             </p>
 
             <ul class="ps-0">
                 <li>User Data - As you engage with our services, we automatically collect and register data from your
                     device and, if applicable, your browser. This information encompasses details like your IP address,
                     cookie data, software and hardware characteristics. While we gather a range of data during your
                     interaction with our website - such as IP address, cookie data, software and hardware attributes,
                     your query's content, and basic usage statistics pertaining to your device and overall service use -
                     this data alone doesn't explicitly identify you to us. Nonetheless, it could contain unique or
                     personal information that you consider important. We collect, retain, and utilize data obtained
                     through Facebook Platform, Twitter, Google, and YouTube, all in accordance with the relevant terms
                     and conditions specified in their privacy policies.
                 </li>
                 <li>User Identification - While using the website doesn't mandate sign-up, certain service features
                     might require registration. In this process, we use third-party authentication mechanisms like
                     Facebook, Google, and Twitter Platforms. Consequently, we obtain your User ID and other relevant
                     data these platforms share with us. To learn about the information we receive from these platforms,
                     please visit their respective websites and review their privacy policies.
                 </li>
                 <li>Cookies - During your utilization of our services, we may transmit one or more cookies. These
                     cookies serve to enhance the caliber of our service by retaining user preferences and tracking user
                     data. Some of our offerings allow you to personalize or download content. For these particular
                     products and services, we record your preferences, along with any self-provided information, as you
                     engage with the content.
                 </li>
 
             </ul>
 
             <b>
                 Use of Information</b>
             <ul>
                 <li>
                     The personal data we gather serves multiple purposes. It enables us to keep you informed about the
                     latest news, upcoming events, security alerts, and administrative messages. Furthermore, this
                     information contributes to the enhancement of our services. If you wish to avoid being on our
                     mailing list, you have the option to opt out at any time by adjusting your preferences on our
                     website.
                 </li>
                 <li>
                     Occasionally, we might employ your personal information to send essential notifications, such as
                     communications about alterations to our terms, conditions, and policies. Given the significance of
                     this information in your interaction with us, opting out of receiving such communications isn't
                     feasible.
 
                 </li>
                 <li>
                     Internally, personal information aids us in endeavors like data analysis, research, and refining our
                     services, products, and customer communication.
 
                 </li>
 
                 <li>
                     For a comprehensive understanding of our users, we also collect Non-Personally Identifiable
                     Information. This assists us in enhancing the website's features and functionality. We may assess
                     trends by studying aggregated demographic and psychographic patterns among users. This
                     non-personally identifiable data might be shared with our partners to facilitate the delivery of
                     customized advertisements, promotions, deals, discounts, and other offers when you use our services.
                     Additionally, when you partake in sweepstakes, games, or other promotions on our website, we collect
                     information from you.
                 </li>
                 <li>
                     We may acquire information about you through various means, including research surveys or data
                     obtained from third parties like verification services, data services, and publicly available
                     sources.
 
                 </li>
                 <li>
                     Incorporating Google API client and YouTube Data API v3 into our products, we uphold compliance with
                     the respective privacy policies, developer policies, API Client Terms of Use, and Privacy Policies
                     of Google and YouTube. Our adherence extends to the terms of service outlined by Google and YouTube.
                     Moreover, we align with the Facebook Platform Policy.
 
                 </li>
                 <li>
                     (Note: This paraphrased content is crafted to communicate the essence of the original text in a
                     unique manner. It is important to verify its accuracy and appropriateness in the context of your
                     specific needs.)
 
                 </li>
 
 
 
             </ul>
 
             <b>Sharing of Personal Information</b>
             <p>
                 We solely disclose personal details to other entities or individuals under specific circumstances:
             </p>
 
 
             <ul>
                 <li>
 
                     We might share specific elements of non-personal data with third parties. This could encompass
                     statistics like the quantity of users utilizing a specific service, those who interacted with a
                     particular advertisement, or those who opted out. Such data doesn't pinpoint individuals'
                     identities.
 
                 </li>
                 <li>
 
                     We share this information with reliable businesses or individuals to handle personal data on our
                     behalf. We ensure that these parties commit to processing such data as per our guidelines, adhering
                     to this Privacy Policy, and implementing suitable measures for confidentiality and security.
                 </li>
 
 
             </ul>
             <b>
                 Security
             </b>
             <p>
                 Safeguarding your personal information is a top priority for us. We implement preventive measures
                 encompassing administrative, technical, and physical safeguards to shield your data from potential loss,
                 theft, misuse, unauthorized access, disclosure, alterations, or destruction. For instance, sensitive
                 details you provide during registration are encrypted to ensure their security.
 
             </p>
             <p>Our website employs an array of security measures, spanning electronic, procedural, and physical aspects.
                 These safeguards are designed to prevent information from being mishandled, altered, or lost, and to
                 thwart any inadvertent destruction or damage to data. When you furnish your information through our
                 website, it is guarded by our robust security systems.
             </p>
             <b>Cookies</b>
             <p>A cookie constitutes a data fragment dispatched from a server to the website, stored on your computer's
                 or mobile device's hard drive ("Cookies"). Session cookies vanish upon closing your browser, while
                 persistent cookies endure, accessible by your browser in subsequent service visits. We may utilize
                 cookie-related data for purposes such as: (a) retaining your data or personal information to obviate
                 re-entry during your current or future service use; (b) furnishing tailored third-party ads, content,
                 and information; (c) gauging the effectiveness of external marketing campaigns; (d) overseeing overall
                 site usage metrics like visitor count and page views; and (e) tracking your participation, submissions,
                 and status in promotions or other activities.
             </p>
 
             <p>We may permit third-party service providers, such as advertisers, to install and read their own cookies,
                 web beacons, and analogous technologies to compile data and/or personal information through services.
                 These third parties collect this data directly and automatically, and we are not involved in these data
                 transmissions. Notably, these third-party cookies are outside the scope of this Privacy Policy.</p>
 
             <b>
                 Changes to the Privacy Policy
             </b>
             <p>
                 We retain the prerogative to modify the Privacy Policy at our exclusive discretion. Any alterations will
                 be communicated to users through the updated Privacy Policy posted on our website. We advise you to
                 frequently peruse this Privacy Policy to stay abreast of any revisions. Your ongoing use of our services
                 will be contingent upon the prevailing Privacy Policy at that time.
 
             </p>
             <b>
                 Compliance with Laws and Law Enforcement
             </b>
             <p>
                 We are committed to cooperating with government entities, law enforcement officials, and private parties
                 to uphold and adhere to legal requirements. We retain the authority to monitor IP addresses for fraud
                 prevention purposes and to share IP addresses with legal authorities when necessary. We will divulge
                 your information to government, law enforcement, or private entities if we reasonably believe it's
                 essential to comply with the law, safeguard the rights of Future Flix Media or a third party, ensure
                 public or individual safety, or prevent or halt any activity we deem illegal, unethical, or legally
                 actionable.
 
             </p>
             <b>
                 Email Notifications
             </b>
 
             <p>
 
                 You might receive communication via email or alternative methods; for instance, promotional offers from
                 other businesses or updates related to your service usage. Additionally, we may receive a confirmation
                 when you open an email from us, aiding in service enhancement. If you wish to avoid receiving emails or
                 other correspondence, please indicate your preference by utilizing the "Unsubscribe" link at the email's
                 bottom or by adjusting your email settings in your account settings page. It's important to note that
                 declining legal notices from us doesn't exempt you from their applicability; these notices continue to
                 govern your service usage, and you bear the responsibility of reviewing them for any changes.
             </p>
 
 
             <b>
                 Contact Information
             </b>
             <p>
                 Should you have inquiries or concerns regarding this Terms of Use, the website, or any information
                 therein, feel free to reach out to us. You can contact us by sending an email to
                 info@futureflixmedia.com.
             </p>
 
 
 
 
         </div>
        
      </main>
    </Layout>
  );
}
