import React, { useEffect, useState } from "react";
import Layout from "../components/Layout";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { BsInstagram, BsFacebook, BsLinkedin } from "react-icons/bs";
import Breadcumb from "../components/Breadcumb";
import axios from "axios";
import { toast } from 'react-toastify';

export default function Contact(props) {
  useEffect(() => {
    props.setProgress(100);
  }, []);

  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    company_name: "",
    website: "",
    email: "",
    number: "",
    message: "",
  });

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    console.log('formData',formData);
    try {
      // Make an API request using axios
      const response = await axios.post("https://www.futureflixmedia.com/api/v1/Contact/insert-contact.php", formData);
      
      // Handle the response if needed (e.g., show a success message)
      console.log("API response:", response);
      toast.success(<><b>{`Thank you for contacting us.`}</b> <br/>{`Our representative will contact you shortly`}</>)

      // Clear the form after successful submission
      setFormData({
        first_name: "",
        last_name: "",
        company_name: "",
        website: "",
        email: "",
        number: "",
        message: "",
      });
    } catch (error) {
      // Handle errors if the API request fails
      console.error("API request error:", error);
    }
  };
  return (
    <Layout noFooterForm={true}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Future Flix | Contact Us </title>
      </Helmet>
      <main class="main contactus-page">
        <Breadcumb title="Let’s Talk Programmatic" desc="" />

        <div class="container">
          <div class="sec-space">
            <div class="heading-center text-center pb-md-5" data-aos="fade-up" data-aos-offset="200">
              Speak With Our Experts
            </div>
            <div class="contact-us-form">
              <div class="contact-info-box">
                <div class="heading-center">Contact us</div>
                <ul class="list-unstyled p-0 cont-info-list">
                  <li class="item">
                    <b>Location:</b>
                    1707, 17th Floor, Indigo Icon, Cluster F, Jumeirah Lake
                    Towers, Dubai
                  </li>
                  <li class="item">
                    <b>Phone:</b>
                    <Link to="tel:+971 4 529 8257" target="_blank" title="Call">
                      +971 4 529 8257
                    </Link>
                    <Link
                      to="tel:+971 50 223 2118"
                      target="_blank"
                      title="Call"
                    >
                      +971 50 223 2118
                    </Link>
                  </li>
                  <li class="item">
                    <b>Email:</b>
                    <Link to="mailto:info@futureflixmedia.com" target="_blank">
                      info@futureflixmedia.com
                    </Link>
                  </li>
                </ul>

                <ul class="list-unstyled p-0 contact-social">
                  <li class="item">
                    <a
                      href="https://www.linkedin.com/company/future-flix-media/"
                      target="_blank"
                      title="Linkdin"
                    >
                      <span
                        className="icon socialicon text-white"
                        style={{ fontSize: "32px" }}
                      >
                        <BsLinkedin></BsLinkedin>
                      </span>
                    </a>
                  </li>
                  <li class="item ">
                    <a
                      href="https://www.instagram.com/futureflixmedia/"
                      target="_blank"
                      title="Instagram"
                    >
                      <span
                        className="icon socialicon text-white"
                        style={{ fontSize: "32px" }}
                      >
                        <BsInstagram></BsInstagram>
                      </span>
                    </a>
                  </li>
                  <li class="item">
                    <a
                      href="https://www.facebook.com/futureflixmedia"
                      target="_blank"
                      title="Facebook"
                    >
                      <span
                        className="icon socialicon text-white"
                        style={{ fontSize: "32px" }}
                      >
                        <BsFacebook></BsFacebook>
                      </span>
                    </a>
                  </li>
                </ul>
              </div>
              <form class="form-02" data-aos="fade-left" onSubmit={handleFormSubmit}>
                <div class="row">
                  <div class="col-12 col-sm-6">
                    <div class="mb-4">
                      <input
                        type="text"
                        class="form-control"
                        id="first_name"
                        placeholder="First name*"
                        required
                        name="first_name"
                        value={formData.first_name}
                        onChange={(e) => setFormData({ ...formData, first_name: e.target.value })}
                      />
                      <label for="first_name" class="sr-only"></label>
                    </div>
                  </div>
                  <div class="col-12 col-sm-6">
                    <div class="mb-4">
                      <input
                        type="text"
                        class="form-control"
                        id="last_name"
                        placeholder="Last name"
                        name="last_name"
                        value={formData.last_name}
                        onChange={(e) => setFormData({ ...formData, last_name: e.target.value })}
                      />
                      <label for="last_name" class="float-label sr-only"></label>
                    </div>
                  </div>
                  <div class="col-12 col-sm-6">
                    <div class="mb-4">
                      <input
                        type="text"
                        class="form-control"
                        id="company_name"
                        placeholder="Company name"
                        name="company_name"
                        value={formData.company_name}
                        onChange={(e) => setFormData({ ...formData, company_name: e.target.value })}
                      />
                      <label
                        for="company_name"
                        class="float-label sr-only"
                      ></label>
                    </div>
                  </div>
                  <div class="col-12 col-sm-6">
                    
                    <div class="mb-4">
                      <input
                        type="text"
                        class="form-control"
                        id="Website"
                        placeholder="Website URL"
                        name="website"
                        value={formData.website}
                        onChange={(e) => setFormData({ ...formData, website: e.target.value })}
                      />
                      <label for="website" class="float-label sr-only"></label>
                    </div>
                  </div>

                  <div class="col-12 col-sm-6">
                    <div class="mb-4">
                      <input
                        type="email"
                        class="form-control"
                        id="email"
                        placeholder="Email*"
                        required
                        name="email"
                        value={formData.email}
                        onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                      />
                      <label for="email" class="float-label sr-only"></label>
                    </div>
                  </div>
                  <div class="col-12 col-sm-6">
                    <div class="mb-4">
                      <input
                        type="text"
                        class="form-control"
                        id="number"
                        placeholder="Phone number*"
                        required
                        name="number"
                        value={formData.number}
                        onChange={(e) => setFormData({ ...formData, number: e.target.value })}
                      />
                      <label for="number" class="float-label sr-only">
                        Phone number*
                      </label>
                    </div>
                  </div>
                  <div class="col-12 col-sm-12">
                    <div class="mb-4 text-area-feild">
                      <textarea
                        id="message"
                        name="message"
                        rows="6"
                        cols="50"
                        class="form-control"
                        value={formData.message}
                        onChange={(e) => setFormData({ ...formData, message: e.target.value })}
                        placeholder="Message"
                      >
                      </textarea>
                    </div>
                  </div>
                  <div class="col-12">
                    <button class="btn btn-primary" type="submit">
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </main>
    </Layout>
  );
}
