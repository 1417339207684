import React, { useEffect, useState } from "react";
import Layout from "../components/Layout";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { BsInstagram, BsFacebook, BsLinkedin } from "react-icons/bs";
import Breadcumb from "../components/Breadcumb";

export default function ContextAd(props) {
  useEffect(() => {
    props.setProgress(100);
  }, []);


  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Future Flix | ContextAd </title>
      </Helmet>
      <main className="main contactus-page">
        <Breadcumb title="X Rae" desc="" />

        <div class="container">
        <div class="sec-space">
            <div class="text-center" data-aos="fade-up">
                <div class="heading-center" >Context is the <span class="text-primary">King</span></div>
                <p>AI-driven context targeting - deliver <br/> personalized ads based on user context and <br/> content on websites.</p>
            </div> 
        </div> 
        </div>


        <section class="section intro-xrae pb-0" style={{backgroundColor: "#acd45f"}}>
            <div class="container">  
                <div class="row align-items-center">
                <div className="col-md-7">
                <div className="img-wrap text-center">
                    <video autoPlay loop className="video-tag" style={{ width: '100%' }}>
                    <source src="./images/advideo.mp4" type="video/mp4" />
                    </video>
                </div>
                </div>
                    <div class="col-md-5">
                        <div class="content">
                            <div class="text-center">
                                <div class="heading-center text-white">Introducing <br/>
                                    X  <span class="text-42">Rae</span></div>
                                <p>The future of advertising is here, and it’s called X rae. Our groundbreaking AI technology unlocks the power of micro-level analysis, delivering ads that resonates with your audience and drive conversions like never before</p>
                            </div> 
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="section image-content-section sec-space">
                <div class="container">
                    <div class="row align-items-center"> 
                        <div class="col-md-6 order-2 order-md-1" data-aos="fade-right">
                            <div class="content">
                                <p>The right side of the brain is more receptive to visual content because it is responsible for processing and interpreting visual information. This side of the brain is associated with creativity, imagination, and visual-spatial skills.</p>
                            </div>
                        </div>
                        <div class="col-md-6 order-1 order-md-2" data-aos="fade-left">
                            <div class="content-image text-md-center">
                                <img src="./images/brain.png" alt="brain" width="620" height="412" />
                            </div>
                        </div>
                    </div>

                    <div class="row align-items-center  flex-md-row-reverse"> 
                        <div class="col-md-6 order-2 order-md-1" data-aos="fade-right">
                            <div class="content ps-md-5 ">
                                <p>Image advertising is important because it helps create a positive perception of a brand or product, attracts attention, and builds brand recognition.
                                </p>
                            </div>
                        </div>
                        <div class="col-md-6 order-1 order-md-2" data-aos="fade-left">
                            <div class="content-image text-md-center">
                                <img src="./images/advertising visual.png" alt="advertising visual" width="643" height="643" />
                            </div>
                        </div>
                    </div>
                    <div class="row align-items-center"> 
                        <div class="col-md-5  order-2 order-md-1" data-aos="fade-right">
                            <div class="content">
                                <p>On average, digital audiences spend approximately 80% of their time consuming visual content
                                </p>
                            </div>
                        </div>
                        <div class="col-md-7  order-1 order-md-2" data-aos="fade-left">
                            <div class="content-image text-md-center mb-md-0 mb-4">
                                <img src="./images/consuming-average.png" alt="consuming-average" width="735" height="410" />
                            </div>
                        </div>
                    </div>
                    
                </div>
        </section>



        <section class="section sec-space pt-0" data-aos="fade-up">
            <div class="container-fluid ps-lg-5 pe-lg-5">
                <div class="four-col-grid mb-3 mb-lg-5">
                    <div class="items text-center mb-sm-0 mb-4">
                        <img src="./images/desk01.png" alt="desk01" width="390" height="205" />
                    </div>
                    <div class="items text-center mb-sm-0 mb-4">
                        <img src="./images/desk02.png" alt="desk02" width="390" height="205" />
                    </div>
                    <div class="items text-center mb-sm-0 mb-4">
                        <img src="./images/desk03.png" alt="desk03" width="390" height="205" />
                    </div>
                    <div class="items text-center mb-sm-0 mb-4">
                        <img src="./images/desk04.png" alt="desk04" width="390" height="205" /> 
                    </div>
                </div>
                <div class="text-center">
                    <a href="https://xrae.futureflixmedia.com/" class="btn btn-primary mt-4">Click for Demo</a>
                </div>
            </div>
        </section>
      </main>
    </Layout>
  );
}
