import React, {useEffect} from "react";
import Layout from "../components/Layout";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

export default function Roastargeting(props) {
    useEffect(() => {
        props.setProgress(100)
    }, []);
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Future Flix | ROAS Targeting </title>
      </Helmet>
      <main className="main retargeting-page">
      <div className="ads-section">
        <div className="container">
          <div className="ads">
            <div className="ads-title">ROAS Targeting</div>
            <p className="ads-des">
              Show ads to customers that actually make you money
            </p>
          </div>
        </div>
      </div>

      <section className="section content-section text-center">
        <div className="container">
          <div className="heading-center">Why target ROAS?</div> 
            <div className="inner-content mx-auto">
              <p>Return On Advertising Spend (ROAS) shows you the revenue a campaign is generating from your campaigns. By setting a ROAS goal you use the full capacity of our AI, finding customers with the highest value to your bottom line.</p>
           </div>
          <p>No expert knowledge is needed. In Future Flix platform, it's as easy as clicking a button.</p>
        </div>
      </section>


      <section className="section content-image-section sec-space sec-bg">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="heading-center">Bring your content to the right person in the right context</div>
              <p>You don't only want new customers. You want high spending customers.</p>
              <p>If you’re looking to increase revenue on your website - our automatic ROAS targeting is for you. By starting ROAS campaigns our AI will work 24/7 to target customers that are generating revenue for your business. Just install the pixels on your website and you're good to go.</p>
            </div>
            <div className="col-lg-6">
              <div className="img">
                <img src="./images/ROAS-with-text-outlined.png" alt="ROAS-with-text-outlined" />
              </div>
            </div>
          </div>
        </div>
      </section>


      <section className="section text-center sec-space">
        <div className="container">
          <div className="heading-center">Setting up a ROAS Compaign</div> 
            <div className="inner-content mx-auto">
              <p>By starting a ROAS target for your display or native ads, your campaign will show ads to customers that actually spend money. The first step in launching a ROAS campaign is installing Match2One's pixel on your website. You then set your product's Average Order Value and ROAS goal when setting up your campaign.</p>
           </div>
          <p>Once your ROAS goal is set - our AI will do the rest.</p>
          <div className="img-wrap mt-3 mt-lg-5">
            <img src="./images/roas-compaign.png" alt="roas-compaign" width="658" height="326" />
          </div>
        </div>
      </section>


      <div className="premium-support-section border-top">
        <div className="container">
          <div className="premium-support-title">
            High Performance - <span>Easy Setup</span> - Premium Support
          </div>
          <Link href="#" className="btn btn-primary">GET STARTED</Link>
        </div>
      </div>
      
    </main>
    </Layout>
  );
}
