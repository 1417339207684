import React, { useEffect } from "react";
import Layout from "../components/Layout";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Breadcumb from "../components/Breadcumb";

export default function Contextual(props) {
  useEffect(() => {
    props.setProgress(100);
  }, []);
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Future Flix | Contextual </title>
      </Helmet>
      <main className="main contextual-advertising-page">
        <Breadcumb
          title="Contextual Advertising Platform"
          desc="Unlock Your Target Audience's Potential with Contextual Advertising"
        ></Breadcumb>

        <div className="demo-section">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-7"  data-aos="fade-right" data-aos-offset="10">
                <div className="demo-title">
                  Contextualizing Ad Experiences , <br />{" "}
                  <span>Future Flix Media</span>
                </div>
                <p className="demo-des">
                  Our groundbreaking AI technology <span>X-rae</span>, unlocks
                  the power of micro-level content analysis and ensures
                  delivering ads in brand safe environment that resonate with
                  the right audience, at the right time while they are in the
                  right frame of mind, without the use of personal data. All in
                  an easy-to-use platform, <b>“CitFlix”</b> with real-time
                  reporting.
                </p>
                <a
                  href="/contextad"
                  className="btn btn-primary"
                >
                  Know More
                </a>
              </div>
              <div className="col-lg-5 demo-img"  data-aos="fade-left" data-aos-offset="10">
                <img
                  src="./images/contextual-advt.png"
                  alt="leading-the-way-for-contextual-advertising"
                  title="leading-the-way-for-contextual-advertising"
                  width="500"
                  height="541"
                />
              </div>
            </div>
          </div>
        </div>

        <section className="section   overflow-hidden sec-space">
          <div className="container">
            <div
              className="text-center"
              data-aos="fade-up"
              data-aos-offset="10"
            >
              <div className="heading-center pb-md-3">
                Future of Advertising with{" "}
                <span className="text-primary">CitFlix</span>
              </div>
              <p>
                {" "}
                CitFlix, an AI driven self served platform enables real-time
                seamless integration, personalized Ad experiences enhancing
                brand engagement and outcomes.
              </p>
            </div>

            <div className="three-col-view contectual-three-card  gap-4">
              <div
                className="items"
                data-aos="fade-up"
                data-aos-anchor-placement="top-bottom"
              >
                <div className="sub-title">Contextual</div>
                <p className="pera">
                  Comprehensive understanding of contextual relevance via text,
                  images, audio & video content
                </p>
                <a href="/contextad" className="btn btn-primary">
                  Discover More
                </a>
              </div>
              <div
                className="items"
                data-aos="fade-up"
                data-aos-anchor-placement="top-bottom"
              >
                <div className="sub-title">Creative</div>
                <p className="pera">
                  Visually appealing and engaging ad format, capture attention
                  and leave a lasting impression on the target audience
                </p>
                <a href="https://creatives.futureflixmedia.com/" className="btn btn-primary">
                  Discover More
                </a>
              </div>
              <div
                className="items"
                data-aos="fade-up"
                data-aos-anchor-placement="top-bottom"
              >
                <div className="sub-title">Convenient</div>
                <p className="pera">
                Timely, comprehensive, accurate and transparent real time reporting
                </p>
                <a href="/convenient" className="btn btn-primary" target="_self">
                  Discover More
                </a>
              </div>
            </div>
          </div>
        </section>
        <div className="advertising-easy-section">
          <div className="container">
            <div className="advertising-easy-title">
              <span>Contextual Advertising</span> Made Easy
            </div>
            <div className="row">
              <div className="col-lg-6 border-right">
                <div className="advertising-easy-col">
                  <div className="advertising-easy-col-text">
                    <div className="title">Real-Time Contextual Targeting</div>
                    <p className="dec">
                      Achieve real-time targeting for relevant content and user
                      interests, enhancing ad effectiveness and engagement.
                    </p>
                  </div>
                  <div className="advertising-easy-img">
                    <img
                      src="./images/real-time-contextual-targeting.png"
                      alt="global-inventory"
                      title="global-inventory"
                      width="185"
                      height="185"
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="advertising-easy-col">
                  <div className="advertising-easy-img">
                    <img
                      src="./images/rapid-campaign-launch.png"
                      alt="global-inventory"
                      title="global-inventory"
                      width="185"
                      height="185"
                    />
                  </div>
                  <div className="advertising-easy-col-text">
                    <div className="title">Contextual Relevance</div>
                    <p className="dec">
                      Ensure ads align with the content, delivering a more
                      meaningful and personalized user experience.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 b-top border-right">
                <div className="advertising-easy-col">
                  <div className="advertising-easy-col-text">
                    <div className="title">Global Audience Exposure</div>
                    <p className="dec">
                      Offering global audience exposure, reaching diverse users
                      and maximizing campaign reach and impact increasing your
                      CTR up to 40%
                    </p>
                  </div>
                  <div className="advertising-easy-img">
                    <img
                      src="./images/relevant-audience-exposure.png"
                      alt="global-inventory"
                      title="global-inventory"
                      width="185"
                      height="185"
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-6 b-top">
                <div className="advertising-easy-col">
                  <div className="advertising-easy-img">
                    <img
                      src="./images/transparent-reporting.png"
                      alt="global-inventory"
                      title="global-inventory"
                      width="185"
                      height="185"
                    />
                  </div>
                  <div className="advertising-easy-col-text">
                    <div className="title">Transparent Reporting</div>
                    <p className="dec">
                      Providing clear insights into ad performance, enabling
                      data-driven decisions and campaign optimization.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section className="section launch-contextual border-top" data-aos="fade-up">
          <div className="text-center mb-4">
            <h2 className="heading-center">Launch a Contextual Campaign</h2>
          </div>
          <div className="container">
            <div className="four-col-grid">
              <div className="item text-center">
                <div className="img">
                  <img
                    src="./images/launch.png"
                    alt="launch"
                    width="227"
                    height="129"
                  />
                </div>
                <div className="title">Get a Contextual Campaign Brief</div>
                <p>
                  Define comprehensive campaign brief outlining the budget and
                  target audience
                </p>
              </div>
              <div className="item text-center">
                <div className="img">
                  <img
                    src="./images/ads.png"
                    alt="ads"
                    width="223"
                    height="123"
                  />
                </div>
                <div className="title">
                  Targeting Contextual Content with Contextual Parameters
                </div>
                <p>
                  Precisely align ads with context using targeted parameters for
                  impactful engagement and resonance.
                </p>
              </div>
              <div className="item text-center">
                <div className="img">
                  <img
                    src="./images/audiance.png"
                    alt="audiance"
                    width="204"
                    height="129"
                  />
                </div>
                <div className="title">
                  Real-Time Bidding with Instant Offerings
                </div>
                <p>
                  Engage in immediate auctions for real-time ad placements with
                  swift, relevant offerings. Boost campaign impact!
                </p>
              </div>
              <div className="item text-center">
                <div className="img">
                  <img
                    src="./images/engage.png"
                    alt="engage"
                    width="218"
                    height="124"
                  />
                </div>
                <div className="title">
                  Campaign Execution with Live Reporting
                </div>
                <p>
                  Execute campaigns while tracking real-time results for instant
                  insights and data-driven optimizations.
                </p>
              </div>
            </div>
          </div>
          <div className="text-center mt-4 mt-lg-5">
              <a href="/contact-us" className="btn btn-primary">
                GET STARTED
              </a>
            </div>
        </section>
        <div className="global-inventory-section" style={{paddingTop:"0px"}}>
          <div className="container">
            <div className="row">
              <div className="col-lg-6" data-aos="fade-right">
                <div className="demo-title">
                  Right Audience, <span> Right Time</span>
                </div>

                <p className="global-inventory-des">
                  Strategically Aligning Your Message with the Optimal Audience
                  at the Perfect Moment. Say goodbye to outdated ads based on
                  old digital trails. Catch their attention when it matters
                  most, right in their digital playground. Don't miss a beat –
                  seize the moment and make every ad count!
                </p>
                <p className="global-inventory-des">
                  Reach out today to explore the boundless possibilities of our
                  ad inventory for your brand.
                </p>
                <a href="/contact-us" target="_self" className="btn btn-primary">
                  Know More
                </a>
              </div>
              <div className="col-lg-6" data-aos="fade-left">
                <img
                  src="../images/right-aud.png"
                  alt="global-inventory"
                  title="global-inventory"
                  width="619"
                  height="429"
                />
              </div>
            </div>
          </div>
        </div>

        <section className="section get-started border-top sec-space">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h2 className="heading-center text-center mb-lg-5">
                  {" "}
                  How to Get Started{" "}
                </h2>
              </div>
            </div>

            <div className="four-col-grid get-started">
              <div className="item arrow-steper">
                <h3>01</h3>
                <p>
                  Schedule a demo now, and within 24 hours, we'll reach out to
                  guide you through our platform and showcase its features.
                </p>
              </div>
              <div className="item arrow-steper active-arrow">
                <h3 className="text-primary">02</h3>
                <p>
                  Define your business goals and our account manager will guide
                  you through the campaign setup.
                </p>
              </div>
              <div className="item  arrow-steper">
                <h3>03</h3>
                <p>
                  Choose the payment method that best suites you. We offer
                  multiple payment options from credit card payment to crypto
                  currency.
                </p>
              </div>
              <div className="item">
                <h3 className="text-primary">04</h3>
                <p>
                  Monitor ad displays across contexts, sites, and apps,
                  pinpointing top-performing channels with precision.
                </p>
              </div>
            </div>

           
          </div>
        </section>

        <div className="premium-support-section border-top">
          <div className="container">
            <div className="premium-support-title">
              High Performance - <span>Easy Setup</span> - Premium Support
            </div>
            <a href="/contact-us" className="btn btn-primary">
              GET STARTED
            </a>
          </div>
        </div>
      </main>
    </Layout>
  );
}
