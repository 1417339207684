import React, { useEffect, useState } from "react";
import Layout from "../components/Layout";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Breadcumb from "../components/Breadcumb";

export default function Convenient(props) {
  const [activeTab, setActiveTab] = useState('inImage');
  const [activeItem, setActiveItem] = useState('addCampaign');
  const [accordionActiveItem, setAccordionActiveItem] = useState(null);
  const [CapActiveTab, setCapActiveTab] = useState('Campaign Builder');

    useEffect(() => {
        props.setProgress(100);
      }, []);
     

      const handleItemClick = (item) => {
        setActiveItem(item);
      };
      const handleTabChange = (tabId) => {
        setActiveTab(tabId);
      };
      const handleTabClick = (tabTitle) => {
        setCapActiveTab(tabTitle);
      };
      const handleAccordionItemClick = (item) => {
        if (accordionActiveItem === item) {
          setAccordionActiveItem(null); // Close the active item if clicked again
        } else {
          setAccordionActiveItem(item);
        }
      };

      const tabContent = {
        'Campaign Builder': (
          <>
            <div className="items" data-aos="fade-up">
              <h3>More Impressions, Lower CPM</h3>
              <p>More Impressions, Lower CPM Adaptive CPM helps you get more impressions for the same price/spend by adjusting your bids to the most optimal level.</p>
            </div>
            <div className="items" data-aos="fade-up">
              <h3>Saved Advertising Budgets</h3>
              <p>More Impressions, Lower CPM Adaptive CPM helps you get more impressions for the same price/spend by adjusting your bids to the most optimal level.</p>
            </div>
            <div className="items" data-aos="fade-up">
              <h3>More Clicks on Your Ad</h3>
              <p>More Impressions, Lower CPM Adaptive CPM helps you get more impressions for the same price/spend by adjusting your bids to the most optimal level.</p>
            </div>
            <div className="items" data-aos="fade-up">
              <h3>Predictable Outcomes</h3>
              <p>More Impressions, Lower CPM Adaptive CPM helps you get more impressions for the same price/spend by adjusting your bids to the most optimal level.</p>
            </div>
          </>
        ),
        'Campaign Management': (
          <>
            <div className="items" data-aos="fade-up">
              <h3>Effortless Campaign Management</h3>
              <p>Simplify the advertising process with a single, user-friendly dashboard. Access all necessary tools to swiftly initiate and manage campaigns, ensuring efficiency and seamless operations.</p>
            </div>
          
            <div className="items" data-aos="fade-up">
              <h3>Exceptional Support</h3>
              <p>Rely on our expert team to guide you through the intricacies of navigating, optimizing, and evaluating your advertising campaigns. Our skilled professionals are here to offer seamless assistance and valuable insights, ensuring your campaign success.</p>
            </div>
            <div className="items" data-aos="fade-up">
              <h3>Streamlined Enhancement</h3>
              <p>Guide the system by specifying preferred sources or exclusions. Employ CPM optimization and click booster functionalities to empower your campaign to achieve its utmost potential and performance.</p>
            </div>
            <div className="items" data-aos="fade-up">
              <h3>Real-Time Bidding Advantage</h3>
              <p>Acquire premium traffic through real-time bidding on programmatic auctions. Optimize your returns with an automated, efficient per-impression media-buying mechanism, ensuring higher profits.</p>
            </div>
          </>
        ),
        'Campaign Analysis': (
          <>
            <div className="items" data-aos="fade-up">
              <h3>Live Analytics Insights</h3>
              <p>Access real-time metrics effortlessly with automatically generated reports. Monitor crucial metrics like ad spend, served impressions, CTR, win rate, and more in real time.</p>
            </div>
            <div className="items" data-aos="fade-up">
              <h3>Enhanced Conversion Tracking</h3>
              <p>Gain deeper insights into your performance by tracking conversions through third-party tags like conversion Pixels or Postbacks.</p>
            </div>
            <div className="items" data-aos="fade-up">
              <h3>Campaign and Creative Evaluation</h3>
              <p>Effortlessly identify top-performing campaigns and creatives right within the DSP dashboard. Swiftly respond to changes and seize emerging opportunities.</p>
            </div>
            <div className="items" data-aos="fade-up">
              <h3>Continual Enhancement</h3>
              <p>For inquiries about campaign performance, analysis, or future improvements, our approachable account managers are at your service.</p>
            </div>
          </>
        ),
      };
  return (
    <Layout>
    <Helmet>
      <meta charSet="utf-8" />
      <title>Future Flix | Convenient </title>
    </Helmet>
    <main className="main contextual-advertising-page">
      <Breadcumb title="Real - Time Ads Management DSP" desc="Contextual advertising now made easy with CitFlix" />

      <div className="demo-section overflow-hidden">
        <div className="container"> 
          <div className="row align-items-center">
            <div className="col-lg-7" data-aos="fade-right"
              data-aos-anchor-placement="top-bottom">
              <div className="demo-title">
                Unlocking New Horizons: <span> Target  <br/>Audience </span>
                with  <span> Future Flix Media</span>
              </div>
              <p className="demo-des">
                Welcome to a world of possibilities where your brand's reach knows no limits. At Future Flix Media, we specialize in targeted marketing strategies that connect you with fresh, untapped audiences. Whether you're expanding your market or introducing a new product, our tailored approach ensures your message resonates where it matters most.
              </p> 
              <a href="#" target="_self"  className="btn btn-primary">Know Nore</a>
           
       
          </div>
          <div className="col-lg-5 demo-img" data-aos="fade-left"
          data-aos-anchor-placement="top-bottom">
            <img
              src="./images/social-media-desktop.png"
              alt="social-media-desktop"
              title=""
              width="521"
              height="520"
            />
          </div>
          </div>
       </div>
     </div>

     
      <section className="section   overflow-hidden sec-space pb-4"> 
        <div className="container">
          <div className="text-center" data-aos="fade-up">
            <div className="heading-center pb-md-5">  <span className="text-primary">Experience a new way to reach <br/>
             </span>  global audience</div> 
          </div> 
         
          <div className="text-center mx-auto" data-aos="fade-up">
            <img src="./images/global-dashboard.png" alt="global-dashboard" width="832" height="623" />
            <p className="fw-bold">Single View Dashboard with <br/> Clear and Easy Navigation</p>
          </div>
        </div> 
      </section>
      
      <section className="section elevate-section  overflow-hidden sec-space pt-4">
       <div className="container">
        <div className="text-center" data-aos="fade-up">
          <div className="heading-center mb-md-5">Elevate Your <span className="text-primary">Experience</span> </div> 
        </div> 


        <div className="row align-items-center">
          <div className="col-md-3 order-2 order-md-1"> 
            <div className="content-info-elecate">
              <div className={`item`}>
                <div className={`title ${activeItem === 'addCampaign' ? 'active' : ''} cursor-pointer`} onClick={() => handleItemClick('addCampaign')}>Add a New Campaign</div>
                <p>Select the campaign type and set the parameters to reach the global audience.</p>
              </div>
              <div className={`item`}>
                <div className={`title ${activeItem === 'uploadCreatives' ? 'active' : ''} cursor-pointer`} onClick={() => handleItemClick('uploadCreatives')}>Upload Creatives</div>
                <p>From selecting your media channel to uploading your creatives</p>
              </div>
              <div className={`item`}>
                <div className={`title ${activeItem === 'realTimeReporting' ? 'active' : ''} cursor-pointer`} onClick={() => handleItemClick('realTimeReporting')}>Real-Time Reporting</div>
                <p>You are all set to launch your campaign and reach the global audience</p>
              </div>
            </div>
        </div>
        <div className="col-md-9 order-1 order-md-2">
            <div className="create-desk-img ps-md-5 text-md-end mb-4">
              {activeItem === 'addCampaign' && (
                <img
                  src="./images/create-new-2.svg" // Replace with the path to your new image
                  alt="add-campaign"
                  title=""
                  width="876"
                  height="432"
                />
              )}
              {activeItem === 'uploadCreatives' && (
                <img
                  src="./images/create-new.svg" // Replace with the path to your new image
                  alt="upload-creatives"
                  title=""
                  width="876"
                  height="432"
                />
              )}
              {activeItem === 'realTimeReporting' && (
                <img
                  src="./images/create-new-3.svg" // Replace with the path to your new image
                  alt="upload-creatives"
                  title=""
                  width="876"
                  height="432"
                />
              )}
            </div>
          </div>
        </div>

       </div>
      </section>

      <section className="section">
          <div className="container">
            <div className="text-center mb-md-5" data-aos="fade-up">
              <div className="heading-center"> Accelerate Media-Buying With <br/>
                The Power of <span className="text-primary"> Automation</span> </div> 

                <p>Unlock the potential of your campaigns like never before. Embrace the efficiency of automated <br/> media-buying and witness your strategies accelerate towards unparalleled results.</p>
            </div> 
         
            <div className="four-col-grid card-border-view sec-space" style={{paddingTop: "0px"}}>
              <div className="item">
                <div className="title">
                  More Visibility at <br/>
                  Lower Cost
                </div>
                <p>Our strategic approach ensures you shine brighter in the digital landscape without breaking the bank</p>
              </div>
              <div className="item">
                <div className="title">
                  Save on  <br/>
                  Ad Spends 
                </div>
                <p> Discover how our expertise maximizes results while minimizing ad spends, putting your resources to work where they matter most.</p>
              </div>
              <div className="item">
                <div className="title">
                  Increased Click <br/> Through Rate
                </div>
                <p> Unleash Higher CTR with Our Expertise. Harness strategies that captivate, compelling users to explore and interact like never before.</p>
              </div>
              <div className="item">
                <div className="title">
                  Anticipated <br/> Results
                </div>
                <p>Explore the Anticipated Results of Our Strategic Solutions. With data-backed insights and innovative approaches, we pave the path to the success you envision.</p>
              </div>
            </div> 
          </div>

      </section> 

      <section className="section dynamic-inter-formate sec-space overflow-hidden" style={{ backgroundColor: '#424242' }}>
      <div className="container">
        <div className="text-center" data-aos="fade-up">
          <div className="heading-center">Dynamic and interactive Ad Formats</div>
          <p className="text-white">Embrace the World of Dynamic and Interactive Ad Formats. Elevate engagement, inspire action, and captivate your audience with immersive advertising that leaves a lasting impression</p>
        </div>

        <ul className="nav nav-tabs justify-content-center sec-space button-group-filtration" style={{border:"none"}} id="adFormatTabs" role="tablist">
          <li className="nav-item" role="presentation">
            <button className={`nav-link btn ${activeTab === 'inImage' ? 'active btn-primary' : 'btn-reverce'} me-3 radius-5`} id="inImage-tab" data-bs-toggle="tab" data-bs-target="#inImage" type="button" role="tab" aria-controls="inImage" aria-selected={activeTab === 'inImage'} onClick={() => handleTabChange('inImage')}>In image</button>
          </li>
          <li className="nav-item" role="presentation">
            <button className={`nav-link btn ${activeTab === 'inScreen' ? 'active btn-primary' : 'btn-reverce'} me-3 radius-5`} id="inScreen-tab" data-bs-toggle="tab" data-bs-target="#inScreen" type="button" role="tab" aria-controls="inScreen" aria-selected={activeTab === 'inScreen'} onClick={() => handleTabChange('inScreen')}>In Screen</button>
          </li>
          <li className="nav-item" role="presentation">
            <button className={`nav-link btn ${activeTab === 'inArticle' ? 'active btn-primary' : 'btn-reverce'} me-3 radius-5`} id="inArticle-tab" data-bs-toggle="tab" data-bs-target="#inArticle" type="button" role="tab" aria-controls="inArticle" aria-selected={activeTab === 'inArticle'} onClick={() => handleTabChange('inArticle')}>In Article</button>
          </li>
          <li className="nav-item" role="presentation">
            <button className={`nav-link btn ${activeTab === 'inSkins' ? 'active btn-primary' : 'btn-reverce'} me-3 radius-5`} id="inSkins-tab" data-bs-toggle="tab" data-bs-target="#inSkins" type="button" role="tab" aria-controls="inSkins" aria-selected={activeTab === 'inSkins'} onClick={() => handleTabChange('inSkins')}>In Skins</button>
          </li>
          <li className="nav-item" role="presentation">
            <button className={`nav-link btn ${activeTab === 'inDisplay' ? 'active btn-primary' : 'btn-reverce'} me-3 radius-5`} id="inDisplay-tab" data-bs-toggle="tab" data-bs-target="#inDisplay" type="button" role="tab" aria-controls="inDisplay" aria-selected={activeTab === 'inDisplay'} onClick={() => handleTabChange('inDisplay')}>Standard Display</button>
          </li>
        </ul>

        <div className="tab-content content-img-section" id="adFormatTabContent">
          <div className={`tab-pane fade ${activeTab === 'inImage' ? 'active show' : ''}`} id="inImage" role="tabpanel" aria-labelledby="inImage-tab">
            <div className="row">
              <div className="col-md-8 text-md-center" data-aos="fade-right">
                <img src="./images/in-image.png" alt="shop-desktop" width="560" height="437" />
              </div>
              <div className="col-md-4" data-aos="fade-left">
                <div className="title">In Image</div>
                <p className="text-white">Flawlessly integrating images with the content, grabbing users' focus where attention peaks.</p>
              </div>
            </div>
          </div>
          <div className={`tab-pane fade ${activeTab === 'inScreen' ? 'active show' : ''}`} id="inScreen" role="tabpanel" aria-labelledby="inScreen-tab">
          <div className="row">
              <div className="col-md-8 text-md-center" data-aos="fade-right">
                <img src="./images/in-screen.png" alt="shop-desktop" width="560" height="437" />
              </div>
              <div className="col-md-4" data-aos="fade-left">
                <div className="title">In Screen</div>
                <p className="text-white">As users scroll through relevant content, our ads stays fixed at the bottom of the page, always in view.</p>
              </div>
            </div>
          </div>
          <div className={`tab-pane fade ${activeTab === 'inArticle' ? 'active show' : ''}`} id="inArticle" role="tabpanel" aria-labelledby="inArticle-tab">
          <div className="row">
              <div className="col-md-8 text-md-center" data-aos="fade-right">
                <img src="./images/in-article.png" alt="shop-desktop" width="560" height="437" />
              </div>
              <div className="col-md-4" data-aos="fade-left">
                <div className="title">In Article</div>
                <p className="text-white">Our dynamic multi-frame interactive unit, illuminating ad messaging seamlessly within contextually-relevant content.</p>
              </div>
            </div>
          </div>
          <div className={`tab-pane fade ${activeTab === 'inSkins' ? 'active show' : ''}`} id="inSkins" role="tabpanel" aria-labelledby="inSkins-tab">
          <div className="row">
              <div className="col-md-8 text-md-center" data-aos="fade-right">
                <img src="./images/skin.png" alt="shop-desktop" width="560" height="437" />
              </div>
              <div className="col-md-4" data-aos="fade-left">
                <div className="title">In Skins</div>
                <p className="text-white">Unveil our screen-expanding contextual ads that seamlessly melds with content, enriched with diverse interactive elements.</p>
              </div>
            </div>
          </div>
          <div className={`tab-pane fade ${activeTab === 'inDisplay' ? 'active show' : ''}`} id="inDisplay" role="tabpanel" aria-labelledby="inDisplay-tab">
          <div className="row">
              <div className="col-md-8 text-md-center" data-aos="fade-right">
                <img src="./images/standerd-display.png" alt="shop-desktop" width="560" height="437" />
              </div>
              <div className="col-md-4" data-aos="fade-left">
                <div className="title">Standard Display</div>
                <p className="text-white">Elevate your strategy with our tailor-made In-Image and In-Screen ads, seamlessly integrating standard banners for effortless, turnkey solutions.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

      <section className="section sec-space rounded-btns-sec " style={{backgroundColor: "#A3CE55"}} data-aos="fade-up"  >
        <div className="container">
          <div className="text-center">
            <div className="heading-center"> Dynamic and interactive Ad Formats</div>  
              <p className="text-white mb-md-5"> Embrace the World of Dynamic and Interactive Ad Formats. Elevate engagement, inspire action, and captivate your audience with immersive advertising that leaves a lasting impression</p>
          </div> 
        <div className="pb-4">
          <div className="d-flex flex-wrap btn-rounded-wrap justify-content-center">
             <a href="#" className="btn-rounded">
              Geolocation
             </a>
             <a href="#" className="btn-rounded">
              GPS
             </a>
             <a href="#" className="btn-rounded">
              Language  
             </a>
             <a href="#" className="btn-rounded">
              Time of the day (dayparting)
             </a>
             <a href="#" className="btn-rounded">
              Timezone
             </a>
             <a href="#" className="btn-rounded">
              Browser 
             </a>
             <a href="#" className="btn-rounded">
              OS
             </a>
             <a href="#" className="btn-rounded">
              Device brand
             </a>
             <a href="#" className="btn-rounded">
              Connection type
             </a>
             <a href="#" className="btn-rounded">
              IAB categories
             </a>
             <a href="#" className="btn-rounded">
              Carriers
             </a>
          </div> 
        </div>

        <div className="text-center">
          <div className="heading-center">Tailored Impressions for Your Success</div>   
        </div>  
        <div className="px-lg-5">
          <div className="d-flex flex-wrap btn-rounded-wrap justify-content-center">
             <a href="#" className="btn-rounded">
              Use filters 
             </a>
             <a href="#" className="btn-rounded">
              Apply your own whitelists and blacklists 
             </a>
             <a href="#" className="btn-rounded">
              Only aim for impressions within bid price you set
             </a>
             <a href="#" className="btn-rounded">
              Set your rules to prioritize or exclude inventory
             </a>
             <a href="#" className="btn-rounded">
              Retarget audiences in your subsequent campaigns
             </a> 
          </div> 
        </div>

        </div>
      </section>
      <section className="section capability-matter sec-space">
      <div className="container">
        <div className="text-center">
          <div className="heading-center mb-md-5">Capabilities That Matter</div>
        </div>
        <div className="row">
          <div className="col-12">
            <ul className="list-unstyled tab-list mb-4 mb-md-5">
              <li className={`item`}>
                <a
                  className={`tab-link ${CapActiveTab === 'Campaign Builder' ? 'active' : ''} cursor-pointer`}
                  onClick={() => handleTabClick('Campaign Builder')}
                  title="Campaign Builder"
                >
                  Campaign Builder
                </a>
              </li>
              <li className={`item`}>
                <a
                  className={`tab-link ${CapActiveTab === 'Campaign Management' ? 'active' : ''} cursor-pointer`}
                  onClick={() => handleTabClick('Campaign Management')}
                  title="Campaign Management"
                >
                  Campaign Management
                </a>
              </li>
              <li className={`item`}>
                <a
                  className={`tab-link ${CapActiveTab === 'Campaign Analysis' ? 'active' : ''} cursor-pointer`}
                  onClick={() => handleTabClick('Campaign Analysis')}
                  title="Campaign Analysis"
                >
                  Campaign Analysis
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div className="grid-cols-2 gap-40 d-grid grid-card gap-26">
            {tabContent[CapActiveTab]}
        </div>
        
        <div className="text-center pt-4 pt-md-5">
          <div className="heading-center">   Trusted Supply Partners</div>   
          <p>We understand that success is built on collaboration and trust. That's why we've carefully curated a network of trusted supply partners who share our commitment to quality and excellence. With these valued partnerships, we ensure that your brand receives the best support, resources, and opportunities in the industry.</p>
        </div>
      </div>
    </section>
     
      
      <section className="section brand-sections sec-space pb-4">
        <div className="container">
          <div className="brand-image mx-auto text-center" data-aos="fade-up"  >
            <img src="./images/brands.png" alt="brand-img" width="" />
          </div>
        </div>
      </section> 

      <section className="section launch-contextual border-top" data-aos="fade-up"
      data-aos-anchor-placement="top-bottom">
        <div className="text-center mb-4">
          <h2 className="heading-center">Starting Is Easy!</h2>
        </div>
        <div className="container">
          <div className="four-col-grid">
            <div className="item text-center">
              <div className="img">
                <img src="./images/launch.png" alt="launch" width="227" height="129" />
              </div>
              <div className="title"> Get a Contextual 
                Campaign Brief</div>
              <p> Define comprehensive campaign brief outlining the budget and target audience
              </p>
            </div>
            <div className="item text-center">
              <div className="img">
                <img src="./images/ads.png" alt="ads" width="223" height="123" />
              </div>
              <div className="title"> Targeting Contextual Content with Contextual Parameters 
              </div>
              <p> Precisely align ads with context using targeted parameters for impactful engagement and resonance.</p>
            </div>
            <div className="item text-center">
              <div className="img">
                <img src="./images/audiance.png" alt="audiance" width="204" height="129" />
              </div>
              <div className="title"> Real-Time Bidding with Instant Offerings
              </div>
              <p> Engage in immediate auctions for real-time ad placements with swift, relevant offerings. Boost campaign impact!</p>
            </div>
            <div className="item text-center">
              <div className="img">
                <img src="./images/engage.png" alt="engage" width="218" height="124" />
              </div>
              <div className="title"> Campaign Execution with Live Reporting
              </div>
              <p> Execute campaigns while tracking real-time results for instant insights and data-driven optimizations.</p>
            </div>


          </div>
          <div className="text-center mt-md-5">
            <a href="#" className="btn btn-primary" data-aos="fade-up">Get Started</a>
          </div>
        </div>
      </section>




      <section className="section">
        <div className="customer-success-section">
          <div className="container">
            <div className="customer-success-title"> Begin Your Targeted Journey Today!</div>
            <p className="customer-success-des mb-md-5">
              Are you ready to take your marketing strategy to the next level? It's time to connect with the audience that truly matters – the ones who are eager to engage with your message and convert into loyal customers.
            </p> 
            <div className="customer-success-buttons">
              <a href="#" className="btn btn-primary">Book demo</a>
              <a href="#" className="btn btn-primary new-primary-button">Call Us NOW</a>
            </div>
          </div>
        </div>
      </section>



      <section className="section custom-accordion sec-space">
      <div className="container">
        <div className="text-center">
          <div className="heading-center">Frequently Asked Questions</div>
        </div>
        <div className="accordion accordion-flush" id="accordionFlushExample">
          <div className="accordion-item mt-3">
            <h2 className="accordion-header" id="flush-headingOne">
              <button
                className={`accordion-button ${accordionActiveItem === 'item1' ? 'expanded' : 'collapsed'}`}
                type="button"
                onClick={() => handleAccordionItemClick('item1')}
              >
                What is contextual advertising?
              </button>
            </h2>
            <div
              id="flush-collapseOne"
              className={`accordion-collapse collapse ${accordionActiveItem === 'item1' ? 'show' : ''}`}
              aria-labelledby="flush-headingOne"
              data-bs-parent="#accordionFlushExample"
            >
              <div className="accordion-body">
              Contextual advertising tailors ads based on the current content or online activity, enhancing relevance and user experience.

              </div>
            </div>
          </div>
          <div className="accordion-item mt-3">
            <h2 className="accordion-header" id="flush-headingTwo">
              <button
                className={`accordion-button ${accordionActiveItem === 'item2' ? 'expanded' : 'collapsed'}`}
                type="button"
                onClick={() => handleAccordionItemClick('item2')}
              >
                How does it work?
              </button>
            </h2>
            <div
              id="flush-collapseTwo"
              className={`accordion-collapse collapse ${accordionActiveItem === 'item2' ? 'show' : ''}`}
              aria-labelledby="flush-headingTwo"
              data-bs-parent="#accordionFlushExample"
            >
              <div className="accordion-body">
              Contextual advertising analyzes webpage content in real-time, selecting ads that match keywords and themes for greater relevance.
              </div>
            </div>
          </div>
          <div className="accordion-item mt-3">
            <h2 className="accordion-header" id="flush-headingThree">
              <button
                className={`accordion-button ${accordionActiveItem === 'item3' ? 'expanded' : 'collapsed'}`}
                type="button"
                onClick={() => handleAccordionItemClick('item3')}
              >
                What are the benefits?
              </button>
            </h2>
            <div
              id="flush-collapseThree"
              className={`accordion-collapse collapse ${accordionActiveItem === 'item3' ? 'show' : ''}`}
              aria-labelledby="flush-headingThree"
              data-bs-parent="#accordionFlushExample"
            >
              <div className="accordion-body">
              Contextual ads improve clicks and conversions for advertisers while providing users with relevant, non-intrusive ad experiences.

              </div>
            </div>
          </div>
          <div className="accordion-item mt-3">
    <h2 className="accordion-header" id="flush-headingFour">
      <button
        className={`accordion-button ${accordionActiveItem === 'item4' ? 'expanded' : 'collapsed'}`}
        type="button"
        onClick={() => handleAccordionItemClick('item4')}
      >
        How is it different from behavioral targeting?
      </button>
    </h2>
    <div
      id="flush-collapseFour"
      className={`accordion-collapse collapse ${accordionActiveItem === 'item4' ? 'show' : ''}`}
      aria-labelledby="flush-headingFour"
      data-bs-parent="#accordionFlushExample"
    >
      <div className="accordion-body">
        Contextual focuses on current content, while behavioral targeting uses past behavior to predict user interests for ad placements.
      </div>
    </div>
  </div>
  
  <div className="accordion-item mt-3">
    <h2 className="accordion-header" id="flush-headingFive">
      <button
        className={`accordion-button ${accordionActiveItem === 'item5' ? 'expanded' : 'collapsed'}`}
        type="button"
        onClick={() => handleAccordionItemClick('item5')}
      >
        What are the challenges?
      </button>
    </h2>
    <div
      id="flush-collapseFive"
      className={`accordion-collapse collapse ${accordionActiveItem === 'item5' ? 'show' : ''}`}
      aria-labelledby="flush-headingFive"
      data-bs-parent="#accordionFlushExample"
    >
      <div className="accordion-body">
        Challenges include occasional context misinterpretation, user privacy concerns, and accurately understanding diverse content nuances.
      </div>
    </div>
  </div>
    {/* Accordion Item 6 */}
    <div className="accordion-item mt-3">
    <h2 className="accordion-header" id="flush-headingSix">
      <button
        className={`accordion-button ${accordionActiveItem === 'item6' ? 'expanded' : 'collapsed'}`}
        type="button"
        onClick={() => handleAccordionItemClick('item6')}
      >
        Is user data used?
      </button>
    </h2>
    <div
      id="flush-collapseSix"
      className={`accordion-collapse collapse ${accordionActiveItem === 'item6' ? 'show' : ''}`}
      aria-labelledby="flush-headingSix"
      data-bs-parent="#accordionFlushExample"
    >
      <div className="accordion-body">
        Contextual advertising relies on content analysis rather than personal data, prioritizing privacy and brand safety.
      </div>
    </div>
  </div>
  
  {/* Accordion Item 7 */}
  <div className="accordion-item mt-3">
    <h2 className="accordion-header" id="flush-headingSeven">
      <button
        className={`accordion-button ${accordionActiveItem === 'item7' ? 'expanded' : 'collapsed'}`}
        type="button"
        onClick={() => handleAccordionItemClick('item7')}
      >
        Which platforms use it?
      </button>
    </h2>
    <div
      id="flush-collapseSeven"
      className={`accordion-collapse collapse ${accordionActiveItem === 'item7' ? 'show' : ''}`}
      aria-labelledby="flush-headingSeven"
      data-bs-parent="#accordionFlushExample"
    >
      <div className="accordion-body">
        Contextual ads are used on websites, apps, and social media, enhancing ad relevance across various digital spaces.
      </div>
    </div>
  </div>
  
  {/* Accordion Item 8 */}
  <div className="accordion-item mt-3">
    <h2 className="accordion-header" id="flush-headingEight">
      <button
        className={`accordion-button ${accordionActiveItem === 'item8' ? 'expanded' : 'collapsed'}`}
        type="button"
        onClick={() => handleAccordionItemClick('item8')}
      >
        Can it enhance brand safety?
      </button>
    </h2>
    <div
      id="flush-collapseEight"
      className={`accordion-collapse collapse ${accordionActiveItem === 'item8' ? 'show' : ''}`}
      aria-labelledby="flush-headingEight"
      data-bs-parent="#accordionFlushExample"
    >
      <div className="accordion-body">
        Yes, by ensuring ads are placed in appropriate contexts, contextual advertising minimizes the risk of controversial placements.
      </div>
    </div>
  </div>
  {/* Accordion Item 9 */}
  <div className="accordion-item mt-3">
    <h2 className="accordion-header" id="flush-headingNine">
      <button
        className={`accordion-button ${accordionActiveItem === 'item9' ? 'expanded' : 'collapsed'}`}
        type="button"
        onClick={() => handleAccordionItemClick('item9')}
      >
        Does it require user history?
      </button>
    </h2>
    <div
      id="flush-collapseNine"
      className={`accordion-collapse collapse ${accordionActiveItem === 'item9' ? 'show' : ''}`}
      aria-labelledby="flush-headingNine"
      data-bs-parent="#accordionFlushExample"
    >
      <div className="accordion-body">
        Unlike behavioral targeting, contextual ads don't rely on user history, making them less reliant on past behavior data.
      </div>
    </div>
  </div>
  
  {/* Accordion Item 10 */}
  <div className="accordion-item mt-3">
    <h2 className="accordion-header" id="flush-headingTen">
      <button
        className={`accordion-button ${accordionActiveItem === 'item10' ? 'expanded' : 'collapsed'}`}
        type="button"
        onClick={() => handleAccordionItemClick('item10')}
      >
        How accurate is it?
      </button>
    </h2>
    <div
      id="flush-collapseTen"
      className={`accordion-collapse collapse ${accordionActiveItem === 'item10' ? 'show' : ''}`}
      aria-labelledby="flush-headingTen"
      data-bs-parent="#accordionFlushExample"
    >
      <div className="accordion-body">
        Accuracy varies; while algorithms strive to understand diverse content, occasional contextual misjudgments can still occur.
      </div>
    </div>
  </div>
        </div>
      </div>
    </section>

      <div className="premium-support-section border-top">
        <div className="container">
          <div className="premium-support-title" data-aos="fade-up">
            High Performance - <span>Easy Setup</span> - Premium Support
          </div>
          <a href="#" className="btn btn-primary" data-aos="fade-up">GET STARTED</a>
        </div>
      </div>
    </main>
  </Layout>
  );
}
